/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from "react";
import SVG from "react-inlinesvg";

import st from "./index.module.scss";

import downloadImg from "resources/img/download.svg";

interface IPhotoLoader {
  photo: string;
  width: number;
  height: number;
  edit: boolean;
  setPhoto: Function;
}

const PhotoLoader: React.FC<IPhotoLoader> = ({
  photo,
  width,
  height,
  edit,
  setPhoto,
}: IPhotoLoader) => {
  const [newImg, setNewImg] = useState<any>("");
  const [url, setUrl] = useState<any>("");
  const inputImg = useRef<HTMLInputElement>(null);

  useEffect(() => {
    generatePreviewImgUrl();
  }, [newImg]);

  function generatePreviewImgUrl() {
    if (newImg) {
      let reader = new FileReader();
      reader.readAsDataURL(newImg);
      reader.onloadend = () => {
        setUrl(reader.result);
      };
    }
  }

  function onPhotoChange(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setNewImg(file);
    setPhoto(file);
  }

  return (
    <div className={st.grid}>
      {photo && !url ? (
        <img
          className={st.foto}
          style={{ width: width + "px", height: height + "px" }}
          src={photo}
        />
      ) : url ? (
        <img
          className={st.foto + " " + (!url ? st.foto_dissable : "")}
          style={{ width: width + "px", height: height + "px" }}
          src={url}
        />
      ) : (
        <div
          className={st.foto + " " + (!url ? st.foto_dissable : "")}
          style={{ width: width + "px", height: height + "px" }}
        />
      )}
      {!edit && (
        <label
          className={st.btnLabel}
          style={{ width: width + "px", height: height + "px" }}
        >
          <SVG src={downloadImg} className={st.btnLabel__img} />
          <input
            type="file"
            className={st.photoInput}
            ref={inputImg}
            accept="image/*"
            onChange={(e) => onPhotoChange(e)}
          />
        </label>
      )}
    </div>
  );
};

export default PhotoLoader;

/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { addMember, changeMember } from "library/services/boardsApi";
import SVG from "react-inlinesvg";
import Input from "library/common/components/Input";
import PhotoLoader from "library/common/components/PhotoLoader";
import { history } from "core/store/configureStore";

import st from "./index.module.scss";

import memberImg from "resources/img/member.svg";
import userImg from "resources/img/members.svg";
import keyImg from "resources/img/key.svg";
import plus from "resources/img/add.svg";
import reportImg from "resources/img/report.svg";
import endImg from "resources/img/end.svg";
import basket from "resources/img/basket.svg";

interface INewMember {
  showMsg: (data: any) => void;
}

const NewMember: React.FC<INewMember> = ({ showMsg }: INewMember) => {
  const [member, setMember] = useState<any>({
    first_name: "",
    last_name: "",
    middle_name: "",
    dataitems: {
      phone: [
        {
          number: "",
          extra_code: "",
        },
      ],
      email: [
        {
          data: "",
        },
      ],
      address: [],
      web: [],
    },
    password: "",
    is_locked: false,
  });
  const [modules, setModules] = useState<any>({
    global_modules: {
      archive: false,
      basket: false,
      clients: false,
      reports: false,
    },
  });
  const [keyTab, setKeyTab] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [photo, setPhoto] = useState<any>({});

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };

  // eslint-disable-next-line no-undef
  const changeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let newInfo = { ...member };
    newInfo[field] = event.target.value;
    setMember(newInfo);
  };

  const changeContacts = (
    event: React.ChangeEvent<HTMLInputElement>,
    contact: string,
    field: string,
    index: number
  ) => {
    let newInfo = { ...member };
    newInfo.dataitems[contact][index][field] = event.target.value;
    setMember(newInfo);
  };

  const changeAccessModules = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let newModules = { ...modules };
    newModules.global_modules[field] = !newModules.global_modules[field];
    setModules(newModules);
  };

  function addPhone() {
    let newInfo = { ...member };
    let phone = {
      number: "",
      extra_code: "",
    };
    newInfo.dataitems.phone.push(phone);
    setMember(newInfo);
  }

  function addMail() {
    let newInfo = { ...member };
    let email = {
      data: "",
    };
    newInfo.dataitems.email.push(email);
    setMember(newInfo);
  }

  function removeUser() {
    history.push("/members/");
  }

  function isValid() {
    let valid = true;
    if (member.first_name.length < 2) {
      valid = false;
      showMsg({ errorColor: true, msg: "Введите имя", show: true });
    } else if (member.last_name.length < 2) {
      valid = false;
      showMsg({ errorColor: true, msg: "Введите фамилию", show: true });
    } else if (member.dataitems.email.length <= 0) {
      valid = member.dataitems.email.every(
        (item) => !/.+@.+\..+/i.test(item.data)
      );
      showMsg({
        errorColor: true,
        msg: "Проверьте написание email",
        show: true,
      });
    } else if (member.password.length < 8) {
      valid = false;
      setKeyTab(true);
      showMsg({
        errorColor: true,
        msg: "Пароль должен быть больше 8 символов",
        show: true,
      });
    }
    return valid;
  }

  function saveUserInfo() {
    if (isValid()) {
      // let data = new FormData();
      //   data.append('logo_url', photo);
      // data.append('first_name', member.first_name);
      // data.append('last_name', member.last_name);
      // data.append('middle_name', member.middle_name);
      // data.append('dataitems', member.dataitems);
      // data.append('global_modules', member.global_modules);
      // data.append('password', member.password);
      addMember(member)
        .then((res) => {
          if (res.status === 201) {
            changeMember(res.data.id, modules);
            showMsg({
              errorColor: false,
              msg: "Сотрудник добавлен",
              show: true,
            });
            gotoMembers();
          }
        })
        .catch(() =>
          showMsg({ errorColor: true, msg: "Что-то пошло не так", show: true })
        );
    }
  }

  function gotoMembers() {
    history.push("/members/");
  }

  return (
    <Modal
      isOpen={true}
      className={st.modal}
      onRequestClose={() => gotoMembers()}
      overlayClassName={st.overlay}
      appElement={document.getElementById("root")}
    >
      <div className={st.head}>
        <div className={st.head__name}>{member.full_name}</div>
        <div
          className={st.userTab + " " + (!keyTab ? st.userTab_active : "")}
          onClick={() => setKeyTab(false)}
        >
          <img src={memberImg} alt="" />
        </div>
        <div
          className={st.keyTab + " " + (keyTab ? st.keyTab_active : "")}
          onClick={() => setKeyTab(true)}
        >
          <img src={keyImg} alt="" />
        </div>
      </div>
      <div className={st.wrap}>
        <div className={st.foto}>
          <PhotoLoader
            photo={member.logo_url}
            width={185}
            height={185}
            edit={disabled}
            setPhoto={setPhoto}
          />
        </div>
        {!keyTab ? (
          <div className={st.container}>
            <div className={st.line}>
              <div className={st.line__name}>ФИО</div>
              <div className={st.line__wrap}>
                <Input
                  width={"159"}
                  value={member.last_name}
                  disabled={disabled}
                  onChange={(e) => changeInput(e, "last_name")}
                />
                <Input
                  width={"159"}
                  value={member.first_name}
                  disabled={disabled}
                  onChange={(e) => changeInput(e, "first_name")}
                />
                <Input
                  width={"159"}
                  value={member.middle_name}
                  disabled={disabled}
                  onChange={(e) => changeInput(e, "middle_name")}
                />
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Телефон</div>
              <div className={st.line__container}>
                {member.dataitems.phone.map((item, index) => (
                  <div className={st.line__wrapper} key={index}>
                    <Input
                      width={"315"}
                      value={item.number}
                      disabled={disabled}
                      onChange={(e) =>
                        changeContacts(e, "phone", "number", index)
                      }
                    />
                    <div className={st.tip}>Доб.</div>
                    <Input
                      width={"83"}
                      value={item.extra_code}
                      disabled={disabled}
                      onChange={(e) =>
                        changeContacts(e, "phone", "extra_code", index)
                      }
                    />
                  </div>
                ))}
              </div>
              <div
                className={st.btn + " " + (!disabled && st.btn_active)}
                onClick={addPhone}
              >
                <SVG src={plus} />
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Email</div>
              <div className={st.line__container}>
                {member.dataitems.email.map((item, index) => (
                  <div className={st.line__wrapper} key={index}>
                    <Input
                      width={"458"}
                      value={item.data}
                      disabled={disabled}
                      onChange={(e) =>
                        changeContacts(e, "email", "data", index)
                      }
                    />
                  </div>
                ))}
              </div>
              <div
                className={st.btn + " " + (!disabled && st.btn_active)}
                onClick={addMail}
              >
                <SVG src={plus} />
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Должность</div>
              <Input
                width={"458"}
                value={member.position}
                disabled={disabled}
                onChange={(e) => changeInput(e, "position")}
              />
            </div>
          </div>
        ) : (
          <div className={st.container}>
            <div className={st.group}>
              <div className={st.group__name}>Доступ к пунктам меню</div>
              <div className={st.group__box}>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.global_modules.clients}
                    className={st.check}
                    onChange={(e) => changeAccessModules(e, "clients")}
                    id="client"
                  />
                  <label className={st.module} htmlFor="client">
                    <SVG src={userImg} className={st.userImg} />
                    <div className={st.module__name}>Клиенты</div>
                  </label>
                </div>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.global_modules.reports}
                    className={st.check}
                    onChange={(e) => changeAccessModules(e, "reports")}
                    id="report"
                  />
                  <label className={st.module} htmlFor="report">
                    <SVG src={reportImg} className={st.userImg} />
                    <div className={st.module__name}>Отчеты</div>
                  </label>
                </div>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.global_modules.archive}
                    className={st.check}
                    onChange={(e) => changeAccessModules(e, "archive")}
                    id="end"
                  />
                  <label className={st.module} htmlFor="end">
                    <SVG src={endImg} className={st.userImg} />
                    <div className={st.module__name}>Архив</div>
                  </label>
                </div>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.global_modules.basket}
                    className={st.check}
                    onChange={(e) => changeAccessModules(e, "basket")}
                    id="basket"
                  />
                  <label className={st.module} htmlFor="basket">
                    <SVG src={basket} className={st.userImg} />
                    <div className={st.module__name}>Корзина</div>
                  </label>
                </div>
              </div>
            </div>
            <div className={st.group}>
              <div className={st.group__loginName}>Логин</div>
              <div className={st.group__login}>{member.username}</div>
              <div className={st.accessBtn}>Ограничить доступ</div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Пароль</div>
              <Input
                width={"498"}
                value={member.password}
                disabled={disabled}
                onChange={(e) => changeInput(e, "password")}
              />
            </div>
          </div>
        )}
      </div>
      <div className={st.foot}>
        {!disabled && (
          <SVG
            src={basket}
            className={st.remove}
            onClick={() => removeUser()}
          />
        )}
        {disabled ? (
          <div className={st.foot__btn} onClick={() => setDisabled(!disabled)}>
            Изменить
          </div>
        ) : (
          <div className={st.foot__btn} onClick={() => saveUserInfo()}>
            Сохранить
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NewMember;

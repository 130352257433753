/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { getPersonalInfo, addAvatar } from "library/services/userApi";
import { changeMember } from "library/services/boardsApi";
import SVG from "react-inlinesvg";
import Input from "library/common/components/Input";
import PhotoLoader from "library/common/components/PhotoLoader";
import PassModal from "./Frames/PassModal";

import st from "./index.module.scss";

import lk from "resources/img/lkab.svg";
import memberImg from "resources/img/member.svg";
import userImg from "resources/img/members.svg";
import keyImg from "resources/img/key.svg";
import plus from "resources/img/add.svg";
import reportImg from "resources/img/report.svg";
import endImg from "resources/img/end.svg";
import basket from "resources/img/basket.svg";

interface IRepsonal {
  showMsg: (data: any) => void;
  id: number;
}

const Personal: React.FC<IRepsonal> = ({ id, showMsg }: IRepsonal) => {
  const [member, setMember] = useState<any>({
    dataitems: {
      phone: [
        {
          number: "",
          extra_code: "",
        },
      ],
      email: [
        {
          data: "",
        },
      ],
      address: [],
      web: [],
    },
  });
  const [keyTab, setKeyTab] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [photo, setPhoto] = useState<any>({});
  const [openPassModal, setOpenPassModal] = useState<boolean>(false);

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };
  const message2 = { errorColor: true, msg: "Фото не сохранено", show: true };
  const messageSuccess = { errorColor: false, msg: "Сохранено", show: true };

  useEffect(() => {
    getInfo();
  }, [id]);

  function getInfo() {
    if (id !== 0) {
      // проверка на 0, так как в initialState в редюсере id = 0
      getPersonalInfo(id)
        .then((res) => {
          let newInfo = res.data;
          if (res.data.dataitems.phone.length === 0) {
            // для нарядной верстки если нет контактов
            let phone = {
              number: "",
            };
            newInfo.dataitems.phone.push(phone);
          }
          if (res.data.dataitems.email.length === 0) {
            let email = {
              data: "",
            };
            newInfo.dataitems.email.push(email);
          }
          setMember(newInfo);
        })
        .catch(() => {
          showMsg(message);
        });
    }
  }

  const changeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let newInfo = { ...member };
    newInfo[field] = event.target.value;
    setMember(newInfo);
  };

  function addPhoto() {
    let data = new FormData();
    data.append("logo", photo);
    addAvatar(id, data)
      .then(() => {
        showMsg(messageSuccess);
      })
      .catch(() => {
        showMsg(message2);
      });
  }

  function saveInfo() {
    changeMember(id, member)
      .then(() => {
        showMsg(messageSuccess);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function saveUserInfo() {
    if (photo.name) {
      addPhoto();
    }
    setDisabled(!disabled);
    saveInfo();
  }

  const changeContacts = (
    event: React.ChangeEvent<HTMLInputElement>,
    contact: string,
    field: string,
    index: number
  ) => {
    let newInfo = { ...member };
    newInfo.dataitems[contact][index][field] = event.target.value;
    setMember(newInfo);
  };

  function addPhone() {
    let newInfo = { ...member };
    let phone = {
      number: "",
      extra_code: "",
    };
    newInfo.dataitems.phone.push(phone);
    setMember(newInfo);
  }

  function addMail() {
    let newInfo = { ...member };
    let email = {
      data: "",
    };
    newInfo.dataitems.email.push(email);
    setMember(newInfo);
  }

  return (
    <div className={st.grid}>
      <div className={st.sidebar}>
        <div className={st.member__link}>
          <img className={st.img} src={lk} alt="" />
          <div className={st.title}>Личные данные</div>
        </div>
      </div>
      <div className={st.container}>
        <Modal
          isOpen={true}
          className={st.modal}
          overlayClassName={st.overlay}
          appElement={document.getElementById("root")}
        >
          <div className={st.head}>
            <div className={st.head__name}>{member.full_name}</div>
            <div
              className={st.userTab + " " + (!keyTab ? st.userTab_active : "")}
              onClick={() => setKeyTab(false)}
            >
              <img src={memberImg} alt="" />
            </div>
            <div
              className={st.keyTab + " " + (keyTab ? st.keyTab_active : "")}
              onClick={() => setKeyTab(true)}
            >
              <img src={keyImg} alt="" />
            </div>
          </div>
          <div className={st.wrap}>
            <div className={st.foto}>
              <PhotoLoader
                photo={member.logo_url}
                width={185}
                height={185}
                edit={disabled}
                setPhoto={setPhoto}
              />
            </div>
            {!keyTab ? (
              <div className={st.container}>
                <div className={st.line}>
                  <div className={st.line__name}>ФИО</div>
                  <div className={st.line__wrap}>
                    <Input
                      width={"159"}
                      value={member.last_name}
                      disabled={disabled}
                      onChange={(e) => changeInput(e, "last_name")}
                    />
                    <Input
                      width={"159"}
                      value={member.first_name}
                      disabled={disabled}
                      onChange={(e) => changeInput(e, "first_name")}
                    />
                    <Input
                      width={"159"}
                      value={member.middle_name}
                      disabled={disabled}
                      onChange={(e) => changeInput(e, "middle_name")}
                    />
                  </div>
                </div>
                <div className={st.line}>
                  <div className={st.line__name}>Телефон</div>
                  <div className={st.line__container}>
                    {member.dataitems.phone.map((item, index) => (
                      <div className={st.line__wrapper} key={index}>
                        <Input
                          width={"315"}
                          value={item.number}
                          disabled={disabled}
                          onChange={(e) =>
                            changeContacts(e, "phone", "number", index)
                          }
                        />
                        <div className={st.tip}>Доб.</div>
                        <Input
                          width={"83"}
                          value={item.extra_code}
                          disabled={disabled}
                          onChange={(e) =>
                            changeContacts(e, "phone", "extra_code", index)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className={st.btn + " " + (!disabled && st.btn_active)}
                    onClick={addPhone}
                  >
                    <SVG src={plus} />
                  </div>
                </div>
                <div className={st.line}>
                  <div className={st.line__name}>Email</div>
                  <div className={st.line__container}>
                    {member.dataitems.email.map((item, index) => (
                      <div className={st.line__wrapper} key={index}>
                        <Input
                          width={"458"}
                          value={item.data}
                          disabled={disabled}
                          onChange={(e) =>
                            changeContacts(e, "email", "data", index)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className={st.btn + " " + (!disabled && st.btn_active)}
                    onClick={addMail}
                  >
                    <SVG src={plus} />
                  </div>
                </div>
                <div className={st.line}>
                  <div className={st.line__name}>Должность</div>
                  <Input
                    width={"458"}
                    value={member.position}
                    disabled={disabled}
                    onChange={(e) => changeInput(e, "position")}
                  />
                </div>
                <div className={st.foot}>
                  {disabled && (
                    <div
                      className={st.foot__btn}
                      onClick={() => setDisabled(!disabled)}
                    >
                      Изменить
                    </div>
                  )}
                  {!disabled && (
                    <div
                      className={st.foot__btn}
                      onClick={() => saveUserInfo()}
                    >
                      Сохранить
                    </div>
                  )}
                  {!disabled && (
                    <div
                      className={st.foot__btn}
                      onClick={() => setDisabled(!disabled)}
                    >
                      Отмена
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className={st.container}>
                <div className={st.group}>
                  <div className={st.group__loginName}>Логин</div>
                  <div className={st.group__login}>{member.username}</div>
                </div>
                <div className={st.foot}>
                  <div
                    className={st.foot__btn}
                    onClick={() => setOpenPassModal(true)}
                  >
                    Сменить пароль
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
      <PassModal open={openPassModal} close={() => setOpenPassModal(false)} />
    </div>
  );
};

export default Personal;

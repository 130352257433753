/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import styles from './index.module.scss';

interface IButtonAutoProps {
    onClick:() => void;
    name: string
}

export function ButtonAuto({ onClick, name }: IButtonAutoProps){
        return(
            <div className={styles.btn} onClick={onClick}>{name}</div>
        )
    }

export default ButtonAuto;

//{ onClick }: EventHandlerProps)
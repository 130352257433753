import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import RightBar from './RightBar';

import { getUserGlobalModules } from 'library/common/selectors/user';
import { isMenuOpen } from 'library/common/selectors/shellSelectors';

const mapStateToProps = (store: RootState) => ({
	isMenuOpen: isMenuOpen(store),
	modules: getUserGlobalModules(store)
});

export default connect(mapStateToProps)(RightBar);
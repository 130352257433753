import React, {useEffect}  from 'react';

import styles from './index.module.scss';

export interface IErrorComponent {
    message: {errorColor: boolean,
                msg: string,
                show?: boolean},
    showMsg: Function;
}

const ErrorComponent:React.FC<IErrorComponent> = ({message, showMsg}: IErrorComponent) => {

    useEffect(() => {
        if(message.show){
            window.setTimeout(function () {
                showMsg({}) 
            }, 3000)
        }
    }, [message.show])
    
        if(!message.show){
            return <div></div>
        }
        return (
            <div className={styles.grid+ " " +(message.errorColor ? '' : styles.green)}>{message.msg}</div>
        )
}

export default ErrorComponent;
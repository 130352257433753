import React, { useEffect, useState } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";

import CardPlaceholder from "../CardPlaceholder"

import styles from './index.module.scss';

interface ICardItem {
    card: any,
    setCardIdAction: Function,
    onCardDrop: Function,
    column: any
}
const Card: React.FC<ICardItem> = ({ card, setCardIdAction, column, onCardDrop }: ICardItem) => {
    const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
    //useDrag - что перетягиваем. useDrop - куда перетягиваем
    //у нас есть drop в компоненте колонки, для перетягивания на колонку
    //карточка одновременно является drag и drop потому, что ее можно перетягивать
    //но при этом для сортировки мы наводим перетянутой карточкой на другие карточки
    //что бы понять, в какую позицию по сортировке ее нужно разместить,
    //в этом случае карточка, на которую мы наводим является мишенью
    const [{ isDragging }, drag] = useDrag({
        //Передаем информацию о карте и куда
        // ее можно перемещать в useDrop колонки
        item: { card: card, type: 'card', transitions: column.transitions },
        end: (item, monitor: DragSourceMonitor) => {
            const dropResult = monitor.getDropResult();
            onCardDrop(item, dropResult)
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: 'card',
        drop: () => ({ column: column.id, position: card.position }),
        canDrop: canCardMove,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    useEffect(()=>{
        /*
        Когда мы перетягиваем карточку, на ее месте нужно оставить ее же мишень
        Иначе, если мы полностью скроем блок, то произойдет скачек вверх, потом сработает
        isOver на нижней карточке и появится ее Placeholder, сместив карточки обратно вниз
        Визуально выглядит это как мигание.
        Проблему решает, если показывать мишень блока, который мы перетягиваем и показывать за счет isOver. Но его isOver не срабатывает потому,
        Что после isDragging блок карточки исчезает, а блок placeholder не успевает появиться, в итоге обертка схлопывается по высоте
        и isOver на карточке, которую мы перетянули не успевает сработать, что приводит к тому же эффекту мигания.
        Когда хватаем карточку, то по флагу isDragging показываем ее собственный placeholder
        Но тогда он будет виден на протяжении всего времени перетягивания, по этому нужен триггер, который выключит 
        этот Placeholder в момент, когда мы перетянем карточку за пределы собственного placeholder
        Для этого, триггер isPlaceholderVisible переключится в false в момент когда успел отработать isOver.
        Таким образом, этот флаг потушит отображние placeholder, которое было запущено флагом isDragging и дальше Placeholder будет показываться 
        за счет isOver до тех пор, пока isOver не перейдет на другую мишень. Когда перетягивание заканчивается, флаг isPlaceholderVisible сбрасывается обратно.
        */
        if(isOver && isDragging){
            setIsPlaceholderVisible(false);
        }
        if(!isDragging){
            setIsPlaceholderVisible(true);
        }
    },[isOver,isDragging])

    function canCardMove(item){
        return item.card.column == column.id || item.transitions.indexOf(column.id) !== -1
    }
    // drag(drop(ref))
    return (
        <div ref={drop} className={
            styles.cardWrapper  +
            (isDragging? " " + styles.cardWrapper_dragging : "") +
            (!isPlaceholderVisible && !isOver? " " + styles.cardWrapper_hidden : "")
            }>
            {((isDragging && isPlaceholderVisible) || isOver) && <CardPlaceholder/>}
            <div
                ref={drag}
                className={
                    styles.card+
                    (isOver? " " + styles.card_over : "")
                }
                style={{ backgroundColor: '#' + card.color }}
                key={card.id} onClick={() => setCardIdAction(card.id)}
            >
                <div className={styles.card__bcg} />
                <div className={styles.title}>{card.title}</div>
                <div className={styles.number}></div>
            </div>
        </div>
    )
}

export default Card;
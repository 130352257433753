/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import Column from "./Frames/Column";

import { getBoard, patchBoard } from 'library/services/boardsApi';

import styles from './index.module.scss';

interface IBoardsItem {
    getAvailableModulesAction: () => void,
    setCardIdAction: (id: number) => void,
    card: { id: number },
    getAllCardsAction: (data) => void,
    orderCards: any[],
    match: any
}

const BoardItem: React.FC<IBoardsItem> = ({
    getAvailableModulesAction,
    setCardIdAction,
    card,
    getAllCardsAction,
    orderCards,
    match
}: IBoardsItem) => {

    useEffect(() => {
        getSteps();
        getAvailableModulesAction();
    }, [])

    useEffect(() => {
        getSteps();
    }, [card])

    function getSteps() {
        getBoard(match.params.id).then(res => {
            getAllCardsAction(res.data.columns)
        }).catch(() => {
            //TODO отобразить ошибку
        })
    }

    function onCardDrop(card, target) {
        //Если позиция и колонка не изменились, значит все осталось как было
        if (target == null || (card.card.position == target.position && card.card.column == target.column)) {
            return null
        }


        // Ищем в orderCards индексы карточки и колонки, откуда переместили
        let sourceColumnIndex = orderCards.findIndex(item => card.card.column == item.id);
        let sourceCardIndex = orderCards[sourceColumnIndex].cards.findIndex(item => item.id == card.card.id);


        // Ищем в orderCards индекс колонки, куда будем перемещать
        let targetColumnIndex = orderCards.findIndex(item => target.column == item.id);
        let targetCardIndex = orderCards[targetColumnIndex].cards.findIndex(item => item.position == target.position);

        targetCardIndex = (targetCardIndex == -1) ? orderCards[targetColumnIndex].cards.length : targetCardIndex

        // Копируем карточку, которую будем перемещать
        let cardObject = JSON.parse(JSON.stringify(orderCards[sourceColumnIndex].cards[sourceCardIndex]));

        let newOrderCards = JSON.parse(JSON.stringify(orderCards));

        //Сортировка внутри одной колонки
        if (card.card.column == target.column) {
            newOrderCards[targetColumnIndex].cards.splice(sourceCardIndex, 1);
            // debugger;
            targetCardIndex = newOrderCards[targetColumnIndex].cards.findIndex(item => item.position == target.position);
            targetCardIndex = (targetCardIndex == -1) ? newOrderCards[targetColumnIndex].cards.length : targetCardIndex

            newOrderCards[targetColumnIndex].cards.splice(targetCardIndex, 0, cardObject);

            //Обновляем position по всей колонке
            newOrderCards[targetColumnIndex].cards = newOrderCards[targetColumnIndex].cards.map((item, index) =>
            ({
                ...item,
                ...{
                    position: index,
                    is_last_position: false
                }
            })
            );
            let cardsLength = newOrderCards[targetColumnIndex].cards.length;
            if(typeof newOrderCards[targetColumnIndex].cards[cardsLength - 1] !== 'undefined'){
                newOrderCards[targetColumnIndex].cards[cardsLength - 1].is_last_position = true;
            }
        }

        //Перемещение в другую колонку
        if (card.card.column !== target.column) {
            //Удаляем карточку из колонки откуда перетащили
            newOrderCards[sourceColumnIndex].cards.splice(sourceCardIndex, 1);

            //Прописываем в копию карточки новы параметры
            cardObject.position = target.position;
            cardObject.column = orderCards[targetColumnIndex].id;

            // Добавляем объект карточки в колонку куда перетащили
            newOrderCards[targetColumnIndex].cards.splice(targetCardIndex, 0, cardObject);


            //Обновляем position по всей исходящей колонке
            newOrderCards[sourceColumnIndex].cards = newOrderCards[sourceColumnIndex].cards.map((item, index) =>
            ({
                ...item,
                ...{
                    position: index,
                    is_last_position: false
                }
            })
            );
            let cardsLength = newOrderCards[sourceColumnIndex].cards.length;
            if(typeof newOrderCards[sourceColumnIndex].cards[cardsLength - 1] !== 'undefined'){
                newOrderCards[sourceColumnIndex].cards[cardsLength - 1].is_last_position = true;
            }


            //Обновляем position по всей входящей колонке
            newOrderCards[targetColumnIndex].cards = newOrderCards[targetColumnIndex].cards.map((item, index) =>
            ({
                ...item,
                ...{
                    position: index,
                    is_last_position: false
                }
            })
            );
            cardsLength = newOrderCards[targetColumnIndex].cards.length;
            if(typeof newOrderCards[targetColumnIndex].cards[cardsLength - 1] !== 'undefined'){
                newOrderCards[targetColumnIndex].cards[cardsLength - 1].is_last_position = true;
            }
        }
        //TODO отправить обновленные данные в апи
        getAllCardsAction(newOrderCards);
        patchCardsInBoard(newOrderCards);
    }

    function patchCardsInBoard(data) {
        let newCards = data.slice();
        let allCards = [];
        for(let i = 0; i < newCards.length; i++){
            allCards = allCards.concat(newCards[i].cards);
        }
        patchBoard(allCards).then(() => {
        }).catch(() => {
            //TODO отобразить ошибку
        })
    }


    return (
        <DndProvider backend={HTML5Backend}>
            <div className={styles.grid}>
                {orderCards.map(item =>
                    <Column
                        onCardDrop={onCardDrop}
                        setCardIdAction={setCardIdAction}
                        column={item}
                        key={item.id}
                        className='column first-column'
                    />
                )}
            </div>
        </DndProvider>
    )
}

export default BoardItem;
/* eslint-disable no-unused-vars */
export enum OrderTypes {
	GET_AVAILABLE_MODULES = '@ORDER/GET_AVAILABLE_MODULES',
	SHOW_AVAILABLE_MODULES = "@ORDER/SHOW_AVAILABLE_MODULES",
	SET_CARD_ID = '@ORDERS/SET_CARD_ID',
	CARD_INFO = '@ORDERS/CARD_INFO',
	HANDLE_CARD_MODAL = '@CARD/HANDLE_CARD_MODAL',
	GET_ALL_CARD = '@CARD/GET_ALL_CARD',
	CHANGE_CARD_COLOR = '@CARD/CHANGE_CARD_COLOR',
	REMOVE_CARD_COLOR = '@CARD/REMOVE_CARD_COLOR',
	OPEN_CARD_ID = '@CARD/OPEN_CARD_ID'
}

/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import { getCardNotes } from "library/services/boardsApi";

import Tz from "./Frames/Tz";
import Price from "./Frames/Price";
import Notes from "./Frames/Notes";
import Settings from "./Frames/Settings";
import Delivery from "./Frames/Delivery";
import RelifeTz from "./Frames/RelifeTz";

import styles from "./index.module.scss";

interface IItem {
  id: number;
  color: string;
  is_last_position: boolean;
  position: number;
  title: string;
  board_id: number;
  column: number;
  prev_column?: any;
  client_id: number;
  client_name: string;
  created: string;
  deleted: boolean;
  user_created: number;
  user_modified: number;
  archive_date?: any;
  delete_date?: any;
  status: string;
}

interface ICard {
  modalCard: boolean;
  card: IItem;
  availableModules: any[];
  openCardModalAction: () => void;
  showMsg: (data: any) => void;
  cardInfoAction: (data: any) => void;
}

const Card: React.FC<ICard> = ({
  modalCard,
  card,
  availableModules,
  openCardModalAction,
  showMsg,
  cardInfoAction,
}: ICard) => {
  const [notes, setNotes] = useState<any>({});
  const [activeModule, setActiveModule] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("");

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };

  function handlerModules(name: string) {
    if (name === "notes_cards") {
      getCardNotes(card.id)
        .then((res) => {
          setNotes(res.data);
          setActiveTab(name);
          setActiveModule("notes");
        })
        .catch(() => {
          showMsg(message);
        });
    } else if (name === "tech_spec_cards") {
      setActiveTab(name);
      setActiveModule("tz");
    } else if (name === "prices_cards") {
      setActiveTab(name);
      setActiveModule("price");
    } else if (name === "settings") {
      setActiveTab(name);
      setActiveModule("settings");
    } else if (name === "delivery") {
      setActiveTab("delivery");
      setActiveModule("delivery");
    }
  }

  function handleModules() {
    switch (activeModule) {
      case "notes":
        return <Notes notes={notes} idCard={card.id} />;
      case "tz":
        return <RelifeTz id={card.id} />;
      case "price":
        return <Price id={card.id} />;
      case "settings":
        return <Settings />;
      case "delivery":
        return <Delivery id={card.id} />;
      default:
        <div></div>;
        break;
    }
  }

  function forClose() {
    setActiveTab("");
    setActiveModule("");
    cardInfoAction({ id: 0, board_id: 0 });
    openCardModalAction();
  }

  return (
    <Modal
      isOpen={!!modalCard}
      className={styles.modal}
      overlayClassName={styles.overlay}
      appElement={document.getElementById("root")}
      onRequestClose={() => forClose()}
    >
      <div className={styles.line}>
        <div
          className={styles.line__status}
          style={{ backgroundColor: "#" + card.color }}
        />
        <div className={styles.line__title}>{card.title}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.container__wrap}>
          {availableModules.map(
            (module) =>
              module.id != 5 && ( // временно скрыт модуль заметок
                <div
                  key={module.id}
                  className={
                    styles.module +
                    " " +
                    (activeTab === module.name ? styles.module_active : "")
                  }
                  onClick={() => handlerModules(module.name)}
                >
                  <SVG src={module.icon} className={styles.module__icon} />
                </div>
              )
          )}
        </div>
        <div
          className={
            styles.container__btn +
            " " +
            (activeTab === "settings" ? styles.container__btn_active : "")
          }
          onClick={() => handlerModules("settings")}
        />
      </div>
      <div
        className={
          styles.wrap + " " + (activeModule !== "" ? styles.wrap_active : "")
        }
      >
        {handleModules()}
      </div>
    </Modal>
  );
};

export default Card;

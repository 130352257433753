import {ActionType, getType} from 'typesafe-actions';

import * as actions from 'library/common/actions/orderActions';

export type OrderState = Readonly<{
    availableModules: any[];
    card: {id:number, board_id: number};
    modalCard: boolean,
    boardCards: any[],
    orderCards: any[]
}>;

const initialState: OrderState = {
    availableModules: [],
    card: {id: 0, board_id: 0},
    modalCard: false,
    boardCards: [],
    orderCards: []
};

export type OrderActions = ActionType<typeof actions>;

export default (state = initialState, action: OrderActions): OrderState => {
    switch (action.type) {
        case getType(actions.getAvailableModulesAction):
			return {
                ...state
            };
        case getType(actions.showAvailableModulesAction):
            return {
                ...state,
                availableModules: action.payload,
            };
        case getType(actions.setCardIdAction):
            return {
                ...state
            };
        case getType(actions.cardInfoAction):
            return {
                ...state,
                card: action.payload,
            };
        case getType(actions.openCardModalAction):
            return {
                ...state,
                modalCard: !state.modalCard,
            };
        case getType(actions.getAllCardsAction):
            return {
                ...state,
                orderCards: action.payload,
            }
        case getType(actions.changeCardColorAction):
            return {
                ...state,
            }
        case getType(actions.removeCardColor):
            return {
                ...state, 
            }
        
        default:
            return state;
    }
}
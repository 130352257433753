import React, { useState, useEffect } from "react";
import NoteList from "./NoteList";
import NoteText from "./NoteText";
import PhotoSlider from "./PhotoSlider";
import SVG from "react-inlinesvg";

import {
  setNewNote,
  deleteNote,
  getCardNotes,
  getActiveNote,
  sendUpdateNoteText,
  addFile,
  getFiles,
} from "library/services/boardsApi";

import layers from "resources/img/layers.svg";

import styles from "./index.module.scss";

interface INotes {
  notes: any[];
  key?: string;
  idCard: number;
}
const Notes: React.FC<INotes> = ({ notes, idCard }: INotes) => {
  const [noteStateList, setNoteList] = useState<any>([]);
  const [activNote, setActiveNote] = useState<any>(null);
  const [crossActive, setCrossActive] = useState<any>(null);
  const [activeNoteObject, setActiveNoteObject] = useState<any>({});
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    createNoteList(notes);
  }, []); //notes, activeNoteObject

  useEffect(() => {
    changeActiveNoteObject(activNote);
  }, [activNote]);

  function createNoteList(data) {
    let newArr: any = [];
    for (let i = 0; i < data.length; i++) {
      let newSortArr = data[i].items.sort(
        (a, b) => Date.parse(b.created) - Date.parse(a.created)
      );
      newArr = [...newSortArr, ...newArr];
    }
    setNoteList(newArr);
  }

  function createNote() {
    let newNote = {
      id: "",
      card: idCard,
      text: "Новая заметка",
      created: new Date(),
      with_file: false,
    };

    setNewNote(newNote)
      .then((res) => {
        setNoteList([res.data, ...noteStateList]);
        return res;
      })
      .then((res) => {
        setActiveNote(0);
        setActiveNoteObject({ id: res.data.id, text: "", created: Date.now() });
      });
  }

  function createCapitalLetters(str: string) {
    let a = str.split("");
    a[0] = a[0]?.toUpperCase();

    for (let i = 0; i < a.length; i++) {
      if (a[i] === "." && a[i + 1] === " ") {
        if (i < a.length - 2) {
          a[i + 2] = a[i + 2].toUpperCase();
        }
      }
    }
    return a.join("");
  }

  function createMassege(e: React.ChangeEvent<HTMLInputElement>) {
    let textMassege = createCapitalLetters(e.target.value);

    setActiveNoteObject({ ...activeNoteObject, text: textMassege });

    let text: any = textMassege || "Новая заметка";
    let newMassege = [...noteStateList];
    let newObj = { ...newMassege[activNote], text };
    newMassege[activNote] = newObj;

    setNoteList(newMassege);
  }

  function saveMassege() {
    if (!activeNoteObject.text && activNote === null) {
      return;
    }
    sendUpdateNoteText(activeNoteObject.id, activeNoteObject);
  }

  function createTitle(text) {
    return text?.match(/.{0,20}/);
  }

  function changeActiveNote(index) {
    setActiveNote(index);
  }

  function changeActiveNoteObject(index) {
    let id = noteStateList[index]?.id;
    // console.log('index', index, 'id', id, 'idCard', idCard)
    if (id === undefined) return;
    getActiveNote(id, idCard).then((res) => setActiveNoteObject(res.data));
  }

  function onCrossClick(index) {
    setCrossActive(index);
  }

  function closeDeleteItem(e) {
    if (e.target.nodeName !== "SPAN") {
      setCrossActive(null);
    }
  }

  function delNote(index) {
    let id = noteStateList[index].id;
    deleteNote(id)
      .then(() => {
        setActiveNoteObject({ text: "" });
        return getCardNotes(idCard);
      })
      .then((res) => {
        createNoteList(res.data);
        setActiveNote(null);
      });
  }

  function generatePreviewImgUrl(newImg) {
    if (newImg) {
      let reader: any = new FileReader();
      if (newImg && newImg.type?.match("image.*")) {
        reader.readAsDataURL(newImg);
        reader.onloadend = () => {
          let newObjFile = new FormData();
          newObjFile.append("id", "");
          newObjFile.append("file", newImg);
          addFile(activeNoteObject.id, newObjFile)
            .then(() => getFiles(activeNoteObject.id))
            .then((res) => {
              setFiles(res.data);
              getActiveNote(activeNoteObject.id, idCard).then((res) =>
                setActiveNoteObject(res.data)
              );
            });
        };
      }
    }
  }

  function onPhotoChange(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    generatePreviewImgUrl(file);
  }

  function createFullDate(date) {
    let optionsFull: any = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    // создание полной даты в сайдбаре справа
    let fullDateNow = new Date(date)
      .toLocaleString("ru-RU", optionsFull)
      .split(" ")
      .map((item, index) =>
        index === 1 ? item[0].toUpperCase() + item.slice(1) : item
      )
      .join(" ");
    return fullDateNow;
  }

  return (
    <div onClick={(e) => closeDeleteItem(e)} className={styles.wrapper}>
      <div className={styles.leftSide}>
        <NoteList
          createNote={createNote}
          noteStateList={noteStateList}
          changeActiveNote={changeActiveNote}
          activNote={activNote}
          onCrossClick={onCrossClick}
          crossActive={crossActive}
          delNote={delNote}
          createTitle={createTitle}
          activeNoteObject={activeNoteObject}
        />
      </div>

      <div className={styles.rightSide}>
        {noteStateList.length === 0 ? (
          <div className={styles.notNote}>
            {'Для создания заметки нажмите "+"'}
          </div>
        ) : null}
        <div className={styles.rightSide__wrapText}>
          <div className={styles.rightSide__data}>
            {activNote !== null && activeNoteObject.created !== undefined
              ? createFullDate(activeNoteObject.created)
              : ""}
          </div>
          <NoteText
            createMassege={createMassege}
            massege={noteStateList[activNote]?.text}
            saveMassege={saveMassege}
            activeNoteObject={activeNoteObject}
          />
        </div>
        <div>
          {activeNoteObject.files?.images && activNote !== null ? (
            <PhotoSlider
              activeNoteObject={activeNoteObject}
              files={files}
              setFiles={setFiles}
              setActiveNoteObject={setActiveNoteObject}
              idCard={idCard}
            />
          ) : null}
          {activNote !== null ? (
            <div className={styles.rightSide__addFile}>
              <label htmlFor={"file"}>
                <SVG src={layers} alt="" className={styles.clip} />
              </label>
              <input type="file" id="file" onChange={(e) => onPhotoChange(e)} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Notes;

/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import {
  myModules,
  getAccessModules,
  postAccessModules,
  deleteAccessModules,
} from "library/services/boardsApi";
import SVG from "react-inlinesvg";

import st from "./index.module.scss";

import demoImg from "resources/img/demoUser.svg";

interface ILayers {
  showMsg: (data: any) => void;
  match: any;
}

const Layers: React.FC<ILayers> = ({ showMsg, match }: ILayers) => {
  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };
  const messageSuccess = {
    errorColor: false,
    msg: "Модуль отключен",
    show: true,
  };
  const messageSuccess2 = {
    errorColor: false,
    msg: "Модуль включен",
    show: true,
  };

  const [module, setModule] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    getModulle();
    getHandleModules();
  }, []);

  function getModulle() {
    myModules()
      .then((res) => {
        setModule(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function getHandleModules() {
    getAccessModules(match.params.id)
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function handleAccess(id, userIndex, userId) {
    const data = {
      module: id,
      user: userId,
    };
    if (users[userIndex].modules.findIndex((item) => item === id) !== -1) {
      deleteAccessModules(match.params.id, data)
        .then(() => {
          showMsg(messageSuccess);
          let items = users.slice();
          let current = items[userIndex].modules.findIndex(
            (item) => item === id
          );
          items[userIndex].modules.splice(current, 1);
          setUsers(items);
        })
        .catch(() => {
          showMsg(message);
        });
    } else if (
      users[userIndex].modules.findIndex((item) => item === id) === -1
    ) {
      postAccessModules(match.params.id, data)
        .then(() => {
          showMsg(messageSuccess2);
          let items = users.slice();
          items[userIndex].modules.push(id);
          setUsers(items);
        })
        .catch(() => {
          showMsg(message);
        });
    } else showMsg(message);
  }

  return (
    <div className={st.grid}>
      <ul className={st.wrap}>
        {users.map((user, index) => (
          <li className={st.user} key={user.id}>
            {user.logo_url ? (
              <img src={user.logo_url} className={st.user__logo} />
            ) : (
              <img src={demoImg} className={st.user__logo} />
            )}
            <div className={st.user__wrap}>
              <div className={st.user__name}>{user.full_name}</div>
              <div className={st.module}>
                {module.map(
                  (mod) =>
                    mod.type === "card" && ( // что-бы выводить тут только модули которые привязаны к конкретной доске
                      <div
                        className={
                          st.module__item +
                          " " +
                          (user.modules.findIndex((item) => item === mod.id) !==
                          -1
                            ? st.module__item_active
                            : "")
                        }
                        key={mod.id}
                        onClick={() => handleAccess(mod.id, index, user.id)}
                      >
                        <SVG src={mod.icon} className={st.module__img} />
                      </div>
                    )
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Layers;

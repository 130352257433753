import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';
import { card } from 'library/common/selectors/orderSelectors';
import { showMsg } from 'library/common/actions/shellActions';
import { cardInfoAction, openCardModalAction } from 'library/common/actions/orderActions'

import Delete from './Delete';

const mapStateToProps = (store: RootState) => ({
    card: card(store)
});

export default connect(mapStateToProps, {
    showMsg,
    cardInfoAction,
    openCardModalAction
})(Delete);
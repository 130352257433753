import React from 'react';

import styles from './index.module.scss';

const ResetPass: React.FC = () => {
    return(
        <div className={styles.grid}>смена пароля</div>
    )
}

export default ResetPass;
/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import {
  getClient,
  getTypeFaces,
  setClientTag,
  changeClient,
  getMetaInfo,
  getHistory,
  deleteConnection,
  getGroups,
  changeGroup,
  deleteGroup,
  createEmployee,
  deleteEmployee,
} from "library/services/clientsApi";
import { history } from "core/store/configureStore";
import InputMask from "react-input-mask";
import SVG from "react-inlinesvg";
import Input from "library/common/components/Input";
import Group from "library/common/components/GroupModal";

import st from "./index.module.scss";

import membersImg from "resources/img/members.svg";
import mailImg from "resources/img/mail.svg";
import noteImg from "resources/img/note.svg";
import phoneImg from "resources/img/phone.svg";
import historyImg from "resources/img/history.svg";
import plus from "resources/img/add.svg";
import linkImg from "resources/img/link.svg";
import reportImg from "resources/img/report.svg";
import endImg from "resources/img/end.svg";
import basket from "resources/img/basket.svg";
import infoImg from "resources/img/info.svg";
import colors from "resources/img/colors.svg";
import cart from "resources/img/cart.svg";
import stepStart from "resources/img/stepStart.svg";
import stepFinish from "resources/img/stepFinish.svg";
import arrow from "resources/img/arrow.svg";
import { get } from "http";

interface IClient {
  showMsg: (data: any) => void;
  tags: any[];
}

const Client: React.FC<IClient> = ({ showMsg, tags }: IClient) => {
  const [member, setMember] = useState<any>({
    employees: [
      {
        dataitems: {
          address: [],
          email: [
            {
              id: 0,
              data: "",
            },
          ],
          phone: [
            {
              extra_code: "",
              id: 0,
              number: "",
            },
          ],
          web: [],
        },
      },
    ],
  });
  const [fakeTitle, setFakeTitle] = useState<string>("");
  const [contact, setContact] = useState<any>({
    address: [],
    email: [
      {
        id: 0,
        data: "",
      },
    ],
    phone: [
      {
        extra_code: "",
        id: 0,
        number: "",
      },
    ],
    web: [],
  });
  const [keyTab, setKeyTab] = useState<string>("info");
  const [modules, setModules] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(true);
  const [forms, setForms] = useState<any[]>([]);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [areaHeight, setAreaHeight] = useState<number>(40);
  const [employeesId, setEmployeesId] = useState<any>({});
  const [meta, setMeta] = useState<any[]>([]);
  const [disabledEmployees, setDisabledEmployees] = useState<boolean>(true);

  //заказы
  const [clientHistory, setClientHistory] = useState<any[]>([]);

  //цветные теги
  const [showColors, setShowColors] = useState<boolean>(false);
  const [colorTag, setColorTag] = useState<number>(1);

  //связи с клиентами
  const [connections, setConnections] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [showGroups, setShowGroups] = useState<boolean>(false);
  const [groupEditor, showGroupEditor] = useState<boolean>(false);
  const [clientGroup, setClientGroup] = useState<any>(null);

  //контакты
  const [newEmployee, setNewEmployee] = useState<any>({
    last_name: "",
    first_name: "",
    function: "",
    dataitems: {
      phone: [
        {
          number: "",
        },
      ],
      email: [
        {
          data: "",
        },
      ],
    },
  });

  const inputArea = useRef<HTMLInputElement | null>(null);

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };

  const messageSuccess = {
    errorColor: false,
    msg: "Изменения сохранены",
    show: true,
  };

  const path = history.location.pathname;
  const idUser = path.substr(path.lastIndexOf("/") + 1);

  useEffect(() => {
    getUserInfo();
  }, [history.location.pathname]);

  useEffect(() => {
    getFormTypes();
  }, []);

  useEffect(() => {
    getAllMetaInfo();
  }, []);

  function getUserInfo() {
    getClient(idUser)
      .then((res) => {
        setMember(res.data);
        setFakeTitle(res.data.title);
        setModules(res.data.global_modules);
        setContact(res.data.dataitems);
        setColorTag(res.data.tag);
        setConnections(res.data.relations);
        setClientGroup(res.data.group);
      })
      .catch(() => {
        showMsg(message);
      });
    getClientHistory(idUser);
  }

  function getFormTypes() {
    getTypeFaces()
      .then((res) => {
        setForms(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function getAllMetaInfo() {
    getMetaInfo()
      .then((res) => {
        setMeta(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
    getAllGroups();
  }

  // eslint-disable-next-line no-undef
  const changeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let newInfo = { ...member };
    newInfo[field] = event.target.value;
    setMember(newInfo);
  };

  const changeArea = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    field: string
  ) => {
    let newTitle = fakeTitle;
    newTitle = event.target.value;
    setFakeTitle(newTitle);
    setTimeout(() => {
      if (null !== inputArea.current) {
        let textareaHeiht = inputArea.current.offsetHeight;
        setAreaHeight(textareaHeiht);
      }
    }, 10);
  };

  function changeForm(form: string) {
    let client = member;
    client.type_face = form;
    setMember(client);
    setDropdown(false);
  }

  function handleClientInfo() {
    let newInfo = { ...member };
    newInfo.title = fakeTitle;
    newInfo.dataitems = contact;
    changeClient(idUser, newInfo)
      .then(() => {
        showMsg(messageSuccess);
      })
      .catch(() => {
        showMsg(message);
      });
    setDisabled(true);
  }

  function addPhone() {
    let newPhones = { ...contact };
    newPhones.phone.push({
      extra_code: "",
      id: 0,
      number: "",
    });
    setContact(newPhones);
  }

  function addEmail() {
    let newEmails = { ...contact };
    newEmails.email.push({
      id: 0,
      data: "",
    });
    setContact(newEmails);
  }

  const changeContacts = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
    index: number,
    type: string
  ) => {
    let newInfo = { ...contact };
    newInfo[type][index][field] = event.target.value;
    setContact(newInfo);
  };

  const checkPhone = () => {
    let newPhones = contact.phone.slice();
    for (let i = 0; i < newPhones.length; i++) {
      if (newPhones[i].number === "") {
        newPhones.splice(i, 1);
      }
    }
    let newContacts = { ...contact };
    newContacts.phone = newPhones;
    setContact(newContacts);
  };

  const checkMail = () => {
    let newMail = contact.email.slice();
    for (let i = 0; i < newMail.length; i++) {
      if (newMail[i].data === "") {
        newMail.splice(i, 1);
      }
    }
    let newContacts = { ...contact };
    newContacts.email = newMail;
    setContact(newContacts);
  };

  const changeEmployeesContacts = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
    index: number,
    type: string,
    userIndex: number
  ) => {
    let newInfo = { ...member };
    member.employees[userIndex].dataitems[type][index][field] =
      event.target.value;
    setMember(newInfo);
  };

  const changeEmployeesFunction = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let newInfo = { ...member };
    member.employees[index].function = event.target.value;
    setMember(newInfo);
  };

  //НАЧАЛО История заказов (history)
  function getClientHistory(id) {
    getHistory(id)
      .then((res) => {
        setClientHistory(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function formatDate(date) {
    return (
      new Date(date).getDate() +
      "." +
      new Date(date).getMonth() +
      "." +
      new Date(date).getFullYear()
    );
  }
  //КОНЕЦ История заказов

  //НАЧАЛО Цветные теги
  function handleColor() {
    if (tags.length !== 0 && colorTag !== null) {
      let colors = tags.slice();
      let index = colors.findIndex((color) => color.id == colorTag);
      return colors[index].hex;
    }
  }

  const setTag = (id, tag) => {
    setShowColors(!showColors);
    setClientTag(id, tag)
      .then((res) => {
        if (res.status === 200) {
          setColorTag(res.data.tag);
        }
      })
      .catch(() => {
        showMsg(message);
      });
  };
  //КОНЕЦ Цветные теги

  //НАЧАЛО Связи с клиентами (connections)
  function getAllGroups() {
    getGroups()
      .then((res) => {
        setGroups(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function deleteClientConnection(client, connection) {
    deleteConnection(client, connection)
      .then((res) => {
        setConnections(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function changeClientGroup(user, newGroup, oldGroup) {
    if (newGroup !== oldGroup && newGroup) {
      changeGroup(user, newGroup)
        .then((res) => {
          setClientGroup(res.data.group);
        })
        .catch(() => {
          showMsg(message);
        });
    } else if (!newGroup && oldGroup) {
      deleteGroup(user, oldGroup)
        .then((res) => {
          setClientGroup(res.data.group);
        })
        .catch(() => {
          showMsg(message);
        });
    }
    setShowGroups(false);
  }
  //КОНЕЦ Связи с клиентами

  //НАЧАЛО Редактирование контактов (employees)
  const createEmployeeName = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let employee = { ...newEmployee };
    employee[field] = event.target.value;
    employee.client = idUser;
    setNewEmployee(employee);
  };

  const createEmployeeContacts = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
    type: string,
    index: number
  ) => {
    let employee = { ...newEmployee };
    employee.dataitems[field][index][type] = event.target.value;
    setNewEmployee(employee);
  };

  const createNewEmployee = (data) => {
    let cleanEmployee = {
      last_name: "",
      first_name: "",
      function: "",
      dataitems: {
        phone: [
          {
            number: "",
          },
        ],
        email: [
          {
            data: "",
          },
        ],
      },
    };
    if (data.last_name && data.first_name) {
      createEmployee(data)
        .then(() => {
          showMsg(messageSuccess);
        })
        .catch(() => {
          showMsg(message);
        });
      getUserInfo();
    }
    setNewEmployee(cleanEmployee);
    setDisabled(!disabled);
  };

  const deleteCurrentEmployee = (id) => {
    deleteEmployee(id)
      .then(() => {})
      .catch(() => {
        showMsg(message);
      });
    getUserInfo();
  };

  const addNewPhone = () => {
    let newPhone = { ...newEmployee };
    if (
      newPhone.dataitems.phone[newPhone.dataitems.phone.length - 1].number
        .length > 0
    ) {
      newPhone.dataitems.phone.push({
        number: "",
      });
      setNewEmployee(newPhone);
    }
  };

  const addNewEmail = () => {
    let newEmail = { ...newEmployee };
    if (
      newEmail.dataitems.email[newEmail.dataitems.email.length - 1].data
        .length > 0
    ) {
      newEmail.dataitems.email.push({
        data: "",
      });
      setNewEmployee(newEmail);
    }
  };
  //КОНЕЦ Редактирование контактов

  function handleTab() {
    switch (keyTab) {
      case "info":
        return (
          <div className={st.container}>
            <div className={st.fakeArea} ref={inputArea}>
              {fakeTitle}
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Название</div>
              <div className={st.line__wrap}>
                <div
                  className={
                    st.dropdown + " " + (!disabled ? st.dropdown_active : "")
                  }
                >
                  <div
                    className={st.dropdown__name}
                    onClick={() => setDropdown(!dropdown)}
                  >
                    {member.type_face}
                  </div>
                  <div
                    className={
                      st.dropdown__container +
                      " " +
                      (dropdown ? st.dropdown__container_active : "")
                    }
                  >
                    <div
                      className={st.dropdown__overlay}
                      onClick={() => setDropdown(false)}
                    />
                    <div className={st.dropdown__wrap}>
                      {forms.map((form, index) => (
                        <div
                          className={st.dropdown__line}
                          key={index}
                          onClick={() => changeForm(form.label)}
                        >
                          {form.label}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <textarea
                  style={{ height: areaHeight + "px" }}
                  className={
                    st.inputName + " " + (disabled ? "" : st.inputName_active)
                  }
                  value={fakeTitle}
                  disabled={disabled}
                  onChange={(e) => changeArea(e, "fakeTitle")}
                />
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Телефон</div>
              <div className={st.phoneWrap}>
                {contact.phone.map((item, index) => (
                  <div className={st.phones} key={item.id}>
                    <InputMask
                      value={item.number}
                      onChange={(e) =>
                        changeContacts(e, "number", index, "phone")
                      }
                      mask="+7 (999) 999-99-99"
                      maskChar=""
                      disabled={disabled}
                      onBlur={() => checkPhone()}
                      className={
                        st.inputPhone +
                        " " +
                        (disabled ? "" : st.inputPhone_active)
                      }
                    />
                    <div className={st.tip}>Доб.</div>
                    <Input
                      width={"83"}
                      value={item.extra_code}
                      disabled={disabled}
                      onChange={(e) =>
                        changeContacts(e, "extra_code", index, "phone")
                      }
                    />
                  </div>
                ))}
                <div></div>
                {!disabled &&
                  contact.phone[contact.phone.length - 1].number !== "" && (
                    <div className={st.btn} onClick={() => addPhone()}>
                      <SVG src={plus} />
                    </div>
                  )}
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Email</div>
              <div className={st.phoneWrap}>
                {contact.email.map((item, index) => (
                  <input
                    className={st.mail + " " + (disabled ? "" : st.mail_active)}
                    key={item.id}
                    value={item.data}
                    disabled={disabled}
                    onBlur={() => checkMail()}
                    onChange={(e) => changeContacts(e, "data", index, "email")}
                  />
                ))}
                {!disabled && (
                  <div className={st.btn} onClick={() => addEmail()}>
                    <SVG src={plus} />
                  </div>
                )}
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Должность</div>
              <Input
                width={"458"}
                value={member.position}
                disabled={disabled}
                onChange={(e) => changeInput(e, "position")}
              />
            </div>
          </div>
        );
      case "employees":
        return (
          <div className={st.employees}>
            {member.employees.map((item, userIndex) => (
              <div className={st.employees__wrap} key={item.id}>
                <div className={st.employees__name}>
                  {item.last_name && item.last_name + " " + item.first_name}
                </div>
                <div
                  className={st.card}
                  onMouseLeave={() => setDisabledEmployees(true)}
                >
                  <div
                    className={
                      st.card__head +
                      " " +
                      (disabledEmployees ? "" : st.card__head_active)
                    }
                  >
                    <div className={st.card__name}>
                      {item.last_name + " " + item.first_name}
                    </div>
                    <input
                      className={
                        st.card__function +
                        " " +
                        (disabledEmployees ? "" : st.card__function_active)
                      }
                      value={item.function}
                      disabled={disabledEmployees}
                      placeholder="Должность"
                      onChange={(e) => changeEmployeesFunction(e, userIndex)}
                    />
                  </div>
                  <div className={st.card__wrap}>
                    <SVG src={phoneImg} className={st.card__img} />
                    <div className={st.card__container}>
                      {item.dataitems.phone.map((phone, index) => (
                        <div className={st.card__line} key={index}>
                          <InputMask
                            value={phone.number}
                            onChange={(e) =>
                              changeEmployeesContacts(
                                e,
                                "number",
                                index,
                                "phone",
                                userIndex
                              )
                            }
                            mask="+7 (999) 999-99-99"
                            maskChar=""
                            placeholder="Teлефон"
                            disabled={disabledEmployees}
                            className={
                              st.card__input +
                              " " +
                              (disabledEmployees ? "" : st.card__input_active)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={st.card__wrap}>
                    <SVG src={mailImg} className={st.card__img} />
                    <div className={st.card__container}>
                      {item.dataitems.email.map((mail, index) => (
                        <div className={st.card__line} key={index}>
                          <input
                            className={
                              st.card__input +
                              " " +
                              (disabledEmployees ? "" : st.card__input_active)
                            }
                            value={mail.data}
                            disabled={disabledEmployees}
                            placeholder="E-mail"
                            onChange={(e) =>
                              changeEmployeesContacts(
                                e,
                                "data",
                                index,
                                "email",
                                userIndex
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={st.card__footer}>
                    {disabledEmployees || (
                      <div
                        className={st.card__btn}
                        onClick={() => deleteCurrentEmployee(item.id)}
                      >
                        Удалить
                      </div>
                    )}
                    {disabledEmployees ? (
                      <div
                        className={st.card__btn_change}
                        onClick={() => setDisabledEmployees(false)}
                      >
                        Изменить
                      </div>
                    ) : (
                      <div
                        className={st.card__btn}
                        onClick={() => handleClientInfo()}
                      >
                        Сохранить
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {/* НАЧАЛО Окно нового контакта */}
            <div
              className={
                st.card +
                " " +
                (!disabled && keyTab === "employees" ? st.card__visible : null)
              }
            >
              <div className={st.card__head}>
                <div className={st.card__name}>
                  <input
                    className={
                      st.card__function + " " + st.card__function_active
                    }
                    placeholder="Фамилия"
                    onChange={(e) => createEmployeeName(e, "last_name")}
                  />
                  <input
                    className={
                      st.card__function + " " + st.card__function_active
                    }
                    placeholder="Имя"
                    onChange={(e) => createEmployeeName(e, "first_name")}
                  />
                </div>
                <input
                  className={st.card__function + " " + st.card__function_active}
                  placeholder="Должность"
                  onChange={(e) => createEmployeeName(e, "function")}
                />
              </div>
              <div className={st.card__wrap}>
                <SVG src={phoneImg} className={st.card__img} />
                <div className={st.card__container}>
                  {newEmployee.dataitems.phone.map((phone, index) => (
                    <div className={st.card__line} key={index}>
                      <InputMask
                        value={phone.number}
                        onChange={(e) =>
                          createEmployeeContacts(e, "phone", "number", index)
                        }
                        mask="+7 (999) 999-99-99"
                        maskChar=""
                        placeholder="Teлефон"
                        disabled={false}
                        className={st.card__input + " " + st.card__input_active}
                      />
                    </div>
                  ))}

                  <div className={st.btn} onClick={() => addNewPhone()}>
                    <SVG src={plus} />
                  </div>
                </div>
              </div>
              <div className={st.card__wrap}>
                <SVG src={mailImg} className={st.card__img} />
                <div className={st.card__container}>
                  {newEmployee.dataitems.email.map((email, index) => (
                    <div className={st.card__line} key={index}>
                      <input
                        className={st.card__input + " " + st.card__input_active}
                        value={email.data}
                        disabled={false}
                        placeholder="E-mail"
                        onChange={(e) =>
                          createEmployeeContacts(e, "email", "data", index)
                        }
                      />
                    </div>
                  ))}
                  <div className={st.btn} onClick={() => addNewEmail()}>
                    <SVG src={plus} />
                  </div>
                </div>
              </div>
              <div className={st.card__footer}>
                <div
                  className={st.card__btn}
                  onClick={() => setDisabled(!disabled)}
                >
                  Отмена
                </div>
                <div
                  className={st.card__btn}
                  onClick={() => createNewEmployee(newEmployee)}
                >
                  Готово
                </div>
              </div>
            </div>
            {/* КОНЕЦ Окно нового контакта */}
          </div>
        );
      case "notes":
        return (
          <div className={st.container}>
            {meta.map((item) => (
              <div key={item.label} className={st.note}>
                <div className={st.note__title}>{item.label}</div>
                <input
                  value={member[item.name]}
                  className={
                    st.note__input +
                    " " +
                    (disabled ? "" : st.note__input_active)
                  }
                  onChange={(e) => changeInput(e, item.name)}
                  disabled={disabled}
                  pattern={item.pattern}
                />
              </div>
            ))}
          </div>
        );
      case "history":
        return (
          <div className={st.container}>
            <div className={st.icons}>
              <div className={st.icons__icon}>
                <SVG src={cart} />
              </div>
              <div className={st.icons__icon}>
                <SVG src={stepStart} />
              </div>
              <div className={st.icons__icon}>
                <SVG src={stepFinish} />
              </div>
            </div>

            {clientHistory.map((item) => (
              <div key={item.label} className={st.order}>
                <div
                  className={st.order__description}
                  style={{ backgroundColor: item.color }}
                >
                  <div className={st.order__title}>{item.title}</div>
                  <div className={st.order__id}>
                    {(item.id / 1000).toString().replace(".", "")}
                  </div>
                </div>

                <div className={st.order__date}>
                  {item.start_date ? formatDate(item.start_date) : null}
                </div>

                <div className={st.order__date}>
                  {item.finish_date ? formatDate(item.start_date) : null}
                </div>
              </div>
            ))}
          </div>
        );
      case "connections":
        return (
          <div className={st.container}>
            <div className={st.connections}>
              <div className={st.connections__title}>
                Связанные между собой клиенты
              </div>
              <div className={st.connections__display}>
                {connections.map((connection) => (
                  <div
                    className={st.connection}
                    key={connection.id}
                    onClick={() =>
                      deleteClientConnection(idUser, connection.id)
                    }
                  >
                    <div className={st.connection__title}>
                      {connection.title}
                    </div>
                    <div className={st.connection__type}>
                      {connection.type_face}
                    </div>
                    <div className={st.connection__delete} />
                  </div>
                ))}
                <div className={st.connections__link}>
                  <SVG src={linkImg} />
                </div>
                <div className={st.connections__note}>
                  Здесь вы можете отобразить связи между клиентами (перетащите
                  карточку из списка клиента в это поле)
                </div>
              </div>
              <div className={st.connections__group}>
                <div className={st.connections__group_description}>
                  Группа клиентов
                </div>
                <div
                  className={
                    st.connections__dropdown +
                    " " +
                    (showGroups && st.connections__dropdown_active)
                  }
                  onClick={() => setShowGroups(!showGroups)}
                >
                  {clientGroup
                    ? groups.find((group) => group.id === clientGroup).name
                    : "Выберете, к какой группе относится данный клиент"}
                  <SVG src={arrow} />
                </div>
              </div>
            </div>
            <div className={st.list + " " + (showGroups && st.list_visible)}>
              <div className={st.list__groups}>
                <div
                  className={st.list__item}
                  onClick={() => changeClientGroup(idUser, null, clientGroup)}
                >
                  Нет группы
                </div>
                {groups.map((group) => (
                  <div
                    className={st.list__item}
                    key={group.id}
                    onClick={() =>
                      changeClientGroup(idUser, group.id, clientGroup)
                    }
                  >
                    {group.name}
                  </div>
                ))}
              </div>
              <div
                className={st.list__add}
                onClick={() => showGroupEditor(true)}
              >
                Добавить группу
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  function handleFooterBtn() {
    if (showColors) {
      return <div className={st.foot__btn_del}>Удалить</div>;
    } else {
      switch (keyTab) {
        case "employees":
          return (
            <div
              className={st.foot__btn}
              onClick={() => setDisabled(!disabled)}
            >
              Новый контакт
            </div>
          );
        case "history":
          return null;
        case "connections":
          return null;
        default:
          return disabled ? (
            <div
              className={st.foot__btn}
              onClick={() => setDisabled(!disabled)}
            >
              Изменить
            </div>
          ) : (
            <div className={st.foot__btn} onClick={() => handleClientInfo()}>
              Сохранить
            </div>
          );
      }
    }
  }

  function closeModal() {
    history.push(`/clients`);
  }

  return (
    <Modal
      isOpen={true}
      className={st.modal}
      overlayClassName={st.overlay}
      onRequestClose={closeModal}
      appElement={document.getElementById("root")}
    >
      <div className={st.head}>
        <div className={st.circle} style={{ borderColor: "#" + handleColor() }}>
          <div
            className={st.circle_tag}
            style={{ backgroundColor: "#" + handleColor() }}
          />
        </div>
        <div className={st.head__name}>{member.title}</div>
        <div
          className={
            st.head__tab + " " + (keyTab === "info" ? st.activeTab : "")
          }
          onClick={() => setKeyTab("info")}
        >
          <SVG src={infoImg} />
        </div>
        <div
          className={
            st.head__tab + " " + (keyTab === "employees" ? st.activeTab : "")
          }
          onClick={() => setKeyTab("employees")}
        >
          <SVG src={membersImg} />
        </div>
        <div
          className={
            st.head__tab + " " + (keyTab === "notes" ? st.activeTab : "")
          }
          onClick={() => setKeyTab("notes")}
        >
          <SVG src={noteImg} />
        </div>
        <div
          className={
            st.head__tab + " " + (keyTab === "history" ? st.activeTab : "")
          }
          onClick={() => setKeyTab("history")}
        >
          <SVG src={historyImg} />
        </div>
        <div
          className={
            st.head__tab + " " + (keyTab === "connections" ? st.activeTab : "")
          }
          onClick={() => setKeyTab("connections")}
        >
          <SVG src={linkImg} />
        </div>
      </div>
      <div className={st.wrap}>{handleTab()}</div>
      <div className={st.foot}>
        <div className={st.colors}>
          <div
            className={st.colors__wrap}
            onClick={() => setShowColors(!showColors)}
          >
            <SVG src={colors} />
          </div>
          <div className={st.colors__list}>
            {tags.map((tag) => (
              <div
                className={st.color}
                key={tag.id}
                onClick={() => setTag(idUser, tag.id)}
              >
                <div
                  className={
                    st.color__circle +
                    " " +
                    (showColors ? "" : st.color__circle_hidden)
                  }
                  style={{ backgroundColor: "#" + tag.hex }}
                />
              </div>
            ))}
          </div>
        </div>
        {handleFooterBtn()}
      </div>
      <Group
        open={groupEditor}
        closeModal={() => showGroupEditor(false)}
        getGroups={() => getAllGroups()}
      />
    </Modal>
  );
};

export default Client;

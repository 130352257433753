import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import { getAvailableModulesAction, setCardIdAction, getAllCardsAction} from 'library/common/actions/orderActions';
import { card, orderCards } from 'library/common/selectors/orderSelectors';

import BoardItem from './BoardItem';

const mapStateToProps = (store: RootState) => ({
    card: card(store),
    orderCards: orderCards(store)
});

export default connect(mapStateToProps, {
    getAvailableModulesAction,
    setCardIdAction,
    getAllCardsAction
})(BoardItem);
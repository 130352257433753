import { RootState } from 'core/store/configureStore';
import { connect } from 'react-redux';

import HistoryCard from './HistoryCard';

import { card } from 'library/common/selectors/orderSelectors';
import { showMsg} from 'library/common/actions/shellActions';
const mapStateToProps = (store: RootState) => ({
    card: card(store)
});


export default connect(mapStateToProps, {
    showMsg
})(HistoryCard);
/* eslint-disable no-debugger */
/* eslint-disable no-undef */
import {takeLatest, call, put} from 'redux-saga/effects';
import {store}  from 'core/store/configureStore';

import { OrderTypes } from 'library/common/types/orderTypes';
import localStorage from 'library/utilities/localStorage';
import { history } from 'core/store/configureStore';
import {  
         getCard,
         myModulesForBoard,
         getCardBasket, 
         getBoard 
} from 'library/services/boardsApi';
import { 
         showAvailableModulesAction,
         cardInfoAction, 
         openCardModalAction 
} from 'library/common/actions/orderActions';
import { showMsg } from 'library/common/actions/shellActions';
import { changeCard } from 'library/services/boardsApi';

export function* getModulesSaga(){
    try {
        let path = history.location.pathname;
        let boardId = path.substr(path.lastIndexOf("/") + 1);
        const modulesResponse = yield call(myModulesForBoard, boardId);
        localStorage.setItem('available_module', modulesResponse.data);
        yield put(showAvailableModulesAction(modulesResponse.data));
       
    } catch (error){
        yield put(showMsg({errorColor: true, msg: 'Что-то пошло не так', show: true}));
    }
}

export function* getCardSaga(id: any){
    try {
        const cardInfo = yield call(getCard, id.payload);
        yield put(cardInfoAction(cardInfo.data));
        yield put(openCardModalAction(true));
    } catch (error){
        yield put(showMsg({errorColor: true, msg: 'Что-то пошло не так', show: true}));
    }
}

export function* changeColorSaga(color: any){ // замена цвета и обновление данных по всем карточкам
    try {
        const state = store.getState();
        const id = state.order.card.id; 
        const newColor = {color: color.payload};
        const newCard = yield call(changeCard, id, newColor);
        yield put(cardInfoAction(newCard.data));
        const boardId = state.order.card.board_id; 
        yield call(getBoard, boardId); // обновляем карточки на борде
    } catch (error){
        yield put(showMsg({errorColor: true, msg: 'Не удалось сменить цвет', show: true}));
    }
}

export function* openCardBasketSaga(id: any){
    try {
        const cardInfo = yield call(getCardBasket, id.payload);
        yield put(cardInfoAction(cardInfo.data));
        yield put(openCardModalAction(true))
    } catch (error){
        yield put(showMsg({errorColor: true, msg: 'Не удалось открыть заказ', show: true}));
    }
}

export default function* watchModules() {
    yield takeLatest(OrderTypes.SET_CARD_ID, getCardSaga);
    yield takeLatest(OrderTypes.CHANGE_CARD_COLOR, changeColorSaga);
    yield takeLatest(OrderTypes.OPEN_CARD_ID, openCardBasketSaga);
    yield takeLatest(OrderTypes.GET_AVAILABLE_MODULES, getModulesSaga);
}

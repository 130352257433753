import React from 'react';
import NoteItem from '../NoteItem';

import styles from './index.module.scss'

interface INoteList {
    key?: string;
    noteStateList: any;
    createNote: () => void;
    changeActiveNote: any;
    activNote: number;
    onCrossClick: Function;
    crossActive: number;
    delNote: Function;
    createTitle: any;
    activeNoteObject: any;
}
const NoteList: React.FC<INoteList> = ({noteStateList, 
                                        createNote, 
                                        changeActiveNote, 
                                        activNote, 
                                        onCrossClick,
                                        crossActive, 
                                        delNote, createTitle, activeNoteObject}: INoteList ) => {

    return (
        <>
            <div className={styles.leftSide__noteList}>
                {noteStateList.map( (item, index) => 
                            <NoteItem 
                                key={item.id}
                                item={item}
                                index={index}
                                changeActiveNote={changeActiveNote}
                                activNote={activNote}
                                onCrossClick={onCrossClick}
                                crossActive={crossActive}
                                delNote={delNote}
                                createTitle={createTitle}
                                activeNoteObject={activeNoteObject}/>
                )}
            </div>
            <div onClick={createNote} className={styles.newNote}>{'+ Новая заметка '}</div>
        </>
    )   
}

export default NoteList;
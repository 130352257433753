/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';

import { getBoards, restoreCard } from 'library/services/boardsApi';

import st from './index.module.scss';
// import Column from 'pages/Boards/Frames/BoardItem/Frames/Column'; TODO не используемые импорты
// import { setCardIdAction } from 'library/common/actions/orderActions';

interface IRestore {
    open: boolean,
    closeModal: () => void,
    card: {id: number},
    showMsg: (data: any) => void,
    cardInfoAction: (data: any) => void,
    openCardModalAction: () => void
}

const Restore: React.FC<IRestore> = ({open, closeModal, card, showMsg, openCardModalAction, cardInfoAction }:IRestore) => {

    const [boards, setBoards] = useState<any[]>([]);

    const message = {errorColor: false, msg: 'Заказ восстановлен', show: true};
    const messageError = {errorColor: true, msg: 'Не удалось восстановить заказ', show: true};
    const messageErrorLoad = {errorColor: true, msg: 'Что-то пошло не так', show: true};
    const newCard = {id:0, board_id: 0}

    useEffect(() => {
        getAllboards()
    },[])

    function getAllboards() {
        getBoards().then(res => {
            setBoards(res.data)
        }).catch(() => {
            showMsg(messageErrorLoad)
        })
    }

    function restoreCardToBoard(cardID, columnID) {
        const data = {
            column: columnID
        }
        restoreCard(cardID,data)
        .then(() => {
            showMsg(message)
            openCardModalAction()
            cardInfoAction(newCard)
        })
        .catch(() => {
            showMsg(messageError)
        })
        
    }

 return (
    <Modal isOpen={open}
           className={st.modal}
            overlayClassName={st.overlay}
            appElement={document.getElementById('root')}
            onRequestClose={() => closeModal()}
            >   
            <div className={st.title}>
                Возврат: Доступные доски
            </div>
            <ul>
                {boards.map((board) =>
                    <li key={board.id} className={st.list}>
                        {board.title}
                        <div className={st.preview}>
                            {board.columns.map((column) => 
                                <div className={st.column} key={column.id} onClick={() => restoreCardToBoard(card.id, column.id)}>
                                    <div className={st.column__head}>
                                        {column.title}
                                    </div>
                                    <div className={st.column__body}>
                                        <div className={st.column__title}>
                                            {column.title}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>
                )}
            </ul>     
    </Modal>
 )
}

export default Restore;
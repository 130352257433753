import React, { useState } from 'react';
import Modal from 'react-modal';

import { createGroup } from 'library/services/clientsApi';

import st from './index.module.scss';

interface IGroup {
  open: boolean,
  closeModal: () => void,
  getGroups: () => void
}



const Group: React.FC<IGroup> = ({open, closeModal, getGroups}: IGroup) => {

  const [group, setGroup] = useState<any>({
    'name': '',
    'description': ''
  })

  const createNewGroup = (data) => {
    createGroup(data).then( () => {
      getGroups()
      closeModal()
    }).catch(() => {
      //TODO отобразить showMsg
    })
  }

  const changeTitle = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    let newGroup = {...group}
    newGroup[field] = event.target.value;
    setGroup(newGroup);
  }

  const changeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>, field: string) => {
    let newGroup = {...group}
    newGroup[field] = event.target.value;
    setGroup(newGroup);
  }

  return (
    <Modal
    isOpen={open}
    onRequestClose={() => closeModal()}
    appElement={document.getElementById('root')}
    className={st.modal}
    overlayClassName={st.overlay}>
      <div className={st.head}>
        <input
          className={st.head__input}
          value={group.title}
          disabled={false}
          onChange={(e) => changeTitle(e, 'name')}
          placeholder={'Введите название'}
        />
      </div>
      <div className={st.description}>
        <div className={st.description__title}>Описание</div>
        <textarea 
          className={st.description__text}
          placeholder={'Описание'} 
          onChange={(e) => changeDescription(e, 'description')}/>
      </div>
      <div className={st.foot}>
        <div className={st.foot__btn} onClick={() => createNewGroup(group)}>Готово</div>
      </div>
    </Modal>
  )
}

export default Group;
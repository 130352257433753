import React, { useState } from "react";
import SVG from "react-inlinesvg";
import Colors from "./Frames/Colors";
import Remove from "library/common/components/RemoveModal";
import Delete from "library/common/components/DeleteModal";
import HistoryCard from "./Frames/HistoryCard";
import Restore from "./Frames/Restore";
import { history } from "core/store/configureStore";

import styles from "./index.module.scss";

import color from "resources/img/colored.svg";
import trash from "resources/img/trash.svg";
import historyImg from "resources/img/history.svg";
import reverse from "resources/img/reverse.svg";

const Settings: React.FC = () => {
  const [menu, setMenu] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [purge, setPurge] = useState<boolean>(false);
  const [historyCard, setHistoryCard] = useState<boolean>(false);
  const [restore, setRestore] = useState<boolean>(false);

  function deleteCard() {
    if (history.location.pathname === "/basket") {
      setPurge(true);
    } else {
      setRemove(true);
    }
  }

  return (
    <div className={styles.grid}>
      <div className={styles.wrap}>
        <SVG src={color} className={styles.img} onClick={() => setMenu(true)} />
        <SVG src={trash} className={styles.img} onClick={() => deleteCard()} />
        {history.location.pathname === "/basket" && (
          <SVG
            src={reverse}
            className={styles.img}
            onClick={() => setRestore(true)}
          />
        )}
        <SVG
          src={historyImg}
          className={styles.history}
          onClick={() => setHistoryCard(true)}
        />
      </div>
      <Colors open={menu} closeModal={() => setMenu(false)} />
      <Remove open={remove} closeModal={() => setRemove(false)} />
      <Delete open={purge} closeModal={() => setPurge(false)} />
      <HistoryCard
        open={historyCard}
        closeModal={() => setHistoryCard(false)}
      />
      <Restore open={restore} closeModal={() => setRestore(false)} />
    </div>
  );
};

export default Settings;

/* eslint-disable no-undef */
/* eslint-disable no-debugger */
import React, { useState } from "react";
import Modal from "react-modal";
import Field from "library/common/components/Field";
import SVG from "react-inlinesvg";
import { postNewPass } from "library/services/userApi";

import st from "./index.module.scss";

import loginEyeIcon from "resources/img/eyeBlack.svg";
import doneImg from "resources/img/bigDone.svg";

interface IPassModal {
  open: boolean;
  close: Function;
  showMsg: Function;
}

const PassModal: React.FC<IPassModal> = ({
  open,
  close,
  showMsg,
}: IPassModal) => {
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [newPass, setNewPass] = useState<string>("");
  const [repPass, setRepPass] = useState<string>("");

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };
  const messageSuccess = {
    errorColor: false,
    msg: "Пароль изменен",
    show: true,
  };

  const clickIcon = (type: string, isPassword: any): void => {
    //debugger
    if (typeof isPassword === "boolean") {
      setShowPassword(!showPassword);
    }
  };

  function changePass() {
    let info = {
      new_pass: newPass,
      old_pass: password,
    };
    postNewPass(info)
      .then(() => {
        showMsg(messageSuccess);
        close();
      })
      .catch(() => {
        showMsg(message);
      });
  }
  return (
    <Modal
      isOpen={open}
      className={st.modal}
      overlayClassName={st.overlay}
      onRequestClose={() => close()}
      appElement={document.getElementById("root")}
    >
      <div className={st.head}>
        <div className={st.head__name}>Смена пароля</div>
      </div>
      <div className={st.title}>Старый пароль</div>
      <div className={st.wrap}>
        <Field
          value={password}
          type={`${showPassword ? "password" : "text"}`}
          onChange={(e) => setPassword(e.target.value)}
          icon={loginEyeIcon}
          clickIcon={clickIcon}
          showPassword={showPassword}
          autoComplete={"new-password"}
          placeholder={""}
        />
      </div>
      <div className={st.title}>Новый пароль</div>
      <input
        type="password"
        className={st.input}
        value={newPass}
        onChange={(e) => setNewPass(e.target.value)}
      />
      <div className={st.title}>Повтор пароля</div>
      <div className={st.container}>
        <input
          type="password"
          className={st.input}
          value={repPass}
          onChange={(e) => setRepPass(e.target.value)}
        />
        <div
          className={
            st.done +
            " " +
            (newPass === repPass && newPass !== "" && newPass.length >= 8
              ? st.done_active
              : "")
          }
        >
          <SVG src={doneImg} />
        </div>
      </div>
      <div className={st.footer}>
        <div className={st.btn} onClick={() => changePass()}>
          Готово
        </div>
      </div>
    </Modal>
  );
};

export default PassModal;

import { fetch } from "../utilities/fetch";

export const getСlients = () => fetch.get("/api/v1/clients/");

export const getClient = (id) => fetch.get(`/api/v1/clients/${id}`);

export const getClientsByTag = (tag) =>
  fetch.get(`/api/v1/clients/tags/${tag}/clients/`);

export const changeClient = (id, data) =>
  fetch.put(`/api/v1/clients/${id}/`, data);

export const getTags = () => fetch.get("/api/v1/clients/tags/");

export const getGroups = () => fetch.get("/api/v1/clients/groups/");

export const getTypeFaces = () => fetch.get("/api/v1/clients/type_faces/");

export const getMetaInfo = () => fetch.get("/api/v1/clients/meta/ip/");

export const setClientTag = (id, tag) =>
  fetch.post(`/api/v1/clients/${id}/tag/${tag}/`);

export const getHistory = (id) => fetch.get(`/api/v1/clients/${id}/history/`);

export const deleteConnection = (client, connection) =>
  fetch.delete(`/api/v1/clients/${client}/relations/${connection}/`);

export const changeGroup = (client, group) =>
  fetch.post(`/api/v1/clients/${client}/group/${group}/`);

export const deleteGroup = (client, group) =>
  fetch.delete(`/api/v1/clients/${client}/group/${group}/`);

export const createGroup = (data) =>
  fetch.post("/api/v1/clients/groups/", data);

export const deleteGroups = (id) =>
  fetch.delete(`/api/v1/clients/groups/${id}/`);

export const createEmployee = (data) =>
  fetch.post("/api/v1/clients/employees/", data);

export const deleteEmployee = (id) =>
  fetch.delete(`/api/v1/clients/employees/${id}/`);

import { RootState } from 'core/store/configureStore';
import { connect } from 'react-redux';

import Restore from './Restore';

import { card } from 'library/common/selectors/orderSelectors';
import { showMsg} from 'library/common/actions/shellActions';
import { cardInfoAction, openCardModalAction } from 'library/common/actions/orderActions'

const mapStateToProps = (store: RootState) => ({
    card: card(store)
});


export default connect(mapStateToProps, {
    showMsg,
    openCardModalAction,
    cardInfoAction
})(Restore);
import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import {modalCard, card, availableModules} from 'library/common/selectors/orderSelectors';
import {openCardModalAction, cardInfoAction} from 'library/common/actions/orderActions';
import {showMsg} from 'library/common/actions/shellActions';

const mapStateToProps = (store: RootState) => ({
    modalCard: modalCard(store),
    card: card(store),
    availableModules: availableModules(store)
        
});

import Card from './Card';

export default connect(mapStateToProps,{
    openCardModalAction,
    cardInfoAction,
    showMsg
})(Card);
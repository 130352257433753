import { ActionType, getType } from 'typesafe-actions';

import * as actions from 'library/common/actions/shellActions';

export type ShellState = Readonly<{
    isMenuOpen: boolean;
    message: object;
    addBtn: Function
}>;

const initialState: ShellState = {
    isMenuOpen: false,
    message: {},
    addBtn: () => { }
};


export type ShellActions = ActionType<typeof actions>;

export default (state = initialState, action: ShellActions): ShellState => {
    switch (action.type) {
        case getType(actions.openMenuAction):
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen,
            };
        case getType(actions.showMsg):
            return {
                ...state,
                message: action.payload,
            };
        case getType(actions.globalAddAction):
            return {
                ...state,
                addBtn: action.payload,
            };
        default:
            return state;
    }
}
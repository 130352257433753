import React from 'react';

import styles from './index.module.scss';

interface IShell {
    children: any;
    isMenuOpen?: boolean;
}

const Shell: React.FC<IShell> = ({isMenuOpen,children}:IShell) => {
    
 return(
    <div className={styles.main+ " "+(isMenuOpen? styles.menuOpen : '')}>
        {children}
    </div>
 )
}


export default Shell;
import {ActionType, getType} from 'typesafe-actions';

import localStorage from 'library/utilities/localStorage';
import {setAuthToken, deleteAuthToken} from 'library/utilities/token';
import * as actions from 'library/common/actions/user';

export type UserState = Readonly<{
	isAuthenticated: boolean;
	email: string;
	isRemember: boolean;
	loginFailed: boolean;
	firstName: string;
	lastName: string;
	id: number;
	globalModules: object
}>;

const token = localStorage.getItem('access_token');

const initialState: UserState = {
	isAuthenticated: !!token,
	email: localStorage.getItem('email') || '',
	isRemember: true,
	loginFailed: false,
	firstName: '',
	lastName: '',
	id: 0,
	globalModules: {
		archive: false,
		basket: false,
		clients: false,
		members: false,
		reports: false
	}
};

if (token) setAuthToken(token);


export type UserActions = ActionType<typeof actions>;


export default (state = initialState, action: UserActions): UserState => {
	switch (action.type) {
		case getType(actions.logOutAction):
			localStorage.removeItem('access_token');
			localStorage.removeItem('email');
			deleteAuthToken();
			return {
				...state,
				isAuthenticated: false,
				loginFailed: false,
			};
		case getType(actions.loginAction):
			return {
				...state,
				isAuthenticated: true,
			};
		case getType(actions.loginActionSuccess):
			return {
				...state,
				isRemember: action.payload.isRemember,
				email: action.payload.username,
				firstName: action.payload.first_name,
				loginFailed: false,
				id: action.payload.id,
				lastName: action.payload.last_name,
			};
		case getType(actions.setUserDataAction):
			return {
				...state,
				email: action.payload.username,
				firstName: action.payload.first_name,
				id: action.payload.id,
				lastName: action.payload.last_name,
				globalModules: action.payload.global_modules
			};
		// case getType(actions.loginActionError):
		// 	return {
		// 		...state,
		// 		loginFailed: true,
		// 	};
		case getType(actions.loginActionWithData):
			return {
				...state,
			};

		default:
			return state;
	}
};

/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React from "react";
import SVG from "react-inlinesvg";

import { Redirect, Link } from "react-router-dom";
import Field from "library/common/components/Field";
import ButtonAuto from "library/common/components/ButtonAuto";

import styles from "./auth.module.scss";

import logo from "resources/img/big_logo.svg";
import loginEyeIcon from "resources/img/eye.svg";
import transparent from "resources/img/transparent.svg";

interface IAuth {
  loginActionWithData: (data: object) => void;
  isAuthenticated: boolean;
  loginFailed: boolean;
}

interface IAuthState {
  email: string;
  password: string;
  emptyEmail: boolean;
  showPassword: boolean;
  showError: boolean;
}

export default class Auth extends React.Component<IAuth, IAuthState> {
  public state: IAuthState = {
    email: "",
    password: "",
    emptyEmail: false,
    showPassword: true,
    showError: false,
  };

  private readonly handleChange = (
    e: any,
    type: string,
    isPassword: any
  ): void => {
    if (typeof isPassword !== "boolean") {
      this.setState({ email: e.target.value, showError: false });
    } else {
      this.setState({ password: e.target.value, showError: false });
    }
    e.persist();
  };

  private readonly clickIcon = (type: string, isPassword: any): void => {
    if (typeof isPassword === "boolean") {
      this.setState({
        showPassword: !this.state.showPassword,
        password: this.state.password,
      });
    }
  };

  private readonly handleSubmit = () => {
    const { password, email } = this.state;
    this.props.loginActionWithData({ email, password });
    this.setState({ showError: true });
  };

  render() {
    const { isAuthenticated, loginFailed } = this.props;
    const { email, password, showPassword, showError } = this.state;
    if (isAuthenticated) {
      return <Redirect to="/boards" />;
    }
    return (
      <div className={styles.grid}>
        <div className={styles.login}>
          <SVG src={logo} className={styles.logo} />
          <div className={styles.wrap}>
            <Field
              value={email}
              type="text"
              onChange={this.handleChange}
              icon={transparent}
              autoComplete={"email"}
              placeholder={"Логин"}
            />
          </div>
          <div className={styles.wrap}>
            <Field
              value={password}
              type={`${showPassword ? "password" : "text"}`}
              onChange={this.handleChange}
              icon={loginEyeIcon}
              clickIcon={this.clickIcon}
              showPassword={showPassword}
              autoComplete={"new-password"}
              placeholder={"Пароль"}
            />
          </div>
          <Link to="/forgot-pass" className={styles.forgot}>
            Забыли пароль?
          </Link>
          {showError && loginFailed && <div>Ошибка!</div>}
          <ButtonAuto name="Войти" onClick={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

import { createAction } from 'typesafe-actions';

import {OrderTypes} from 'library/common/types/orderTypes';

export const getAvailableModulesAction = createAction(OrderTypes.GET_AVAILABLE_MODULES)<any>();

export const showAvailableModulesAction = createAction(OrderTypes.SHOW_AVAILABLE_MODULES)<any>();

export const setCardIdAction = createAction(OrderTypes.SET_CARD_ID)<any>();

export const cardInfoAction = createAction(OrderTypes.CARD_INFO)<any>();

export const openCardModalAction = createAction(OrderTypes.HANDLE_CARD_MODAL)<any>();

export const getAllCardsAction = createAction(OrderTypes.GET_ALL_CARD)<any>();

export const changeCardColorAction = createAction(OrderTypes.CHANGE_CARD_COLOR)<any>();

export const removeCardColor = createAction(OrderTypes.REMOVE_CARD_COLOR)<any>();

export const openCardBasketAction = createAction(OrderTypes.OPEN_CARD_ID)<any>();
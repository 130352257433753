import {takeLatest} from 'redux-saga/effects';

//import {testAction} from '../actions/testActions';
import {TestTypes} from '../types/testTypes';

function* testActionSaga() {
	yield true;
}

export default function* watchTest() {
	yield takeLatest(TestTypes.TEST_ACTION, testActionSaga);
}

import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import testReducer from 'library/common/reducers/testReducer';
import userReducer from 'library/common/reducers/userReducer';
import shellReducer from 'library/common/reducers/shellReducer';
import orderReducer from 'library/common/reducers/orderReducer';

const createCoreReducer = (history: any) => combineReducers({
	router: connectRouter(history),
	test: testReducer,
	user: userReducer,
	shell: shellReducer,
	order: orderReducer
});

export default createCoreReducer;

/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-debugger */
import React, { useEffect, useState, useRef } from "react";
import {
  putStages,
  getStages,
  deleteStages,
  postStages,
} from "library/services/boardsApi";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Step from "./Frames/Step";

import st from "./index.module.scss";

import startImg from "resources/img/stepStart.svg";
import finishImg from "resources/img/stepFinish.svg";
import leftImg from "resources/img/blueArrowLeft.svg";
import rightImg from "resources/img/blueArrowRight.svg";
import plusImg from "resources/img/add.svg";

interface ISteps {
  match: any;
  showMsg: (data: any) => void;
}

const Steps: React.FC<ISteps> = ({ match, showMsg }: ISteps) => {
  const [step, setStep] = useState<any[]>([]);
  const [edit, setEdit] = useState<number>(-1);
  const [modal, setModal] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [newOne, setNewOne] = useState<any>({
    title: "",
    type: "regular",
  });

  const newTitle = useRef<HTMLInputElement>(null);

  const message = {
    errorColor: true,
    msg: "Не удалось загрузить этапы",
    show: true,
  };
  const messageSuccess = { errorColor: false, msg: "Этап удален", show: true };
  const create = { errorColor: false, msg: "Этап создан", show: true };
  const changeSuccess = { errorColor: false, msg: "Cохранено", show: true };
  const changeError = {
    errorColor: false,
    msg: "Что-то пошло не так",
    show: true,
  };

  useEffect(() => {
    getSteps();
  }, []);

  function getSteps() {
    getStages(match.params.id)
      .then((res) => {
        setStep(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  const handleColumns = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: string
  ) => {
    let newSteps = step.slice();
    newSteps[index][field] = event.target.value;
    setStep(newSteps);
  };

  function changeStep(index) {
    let data = step[index];
    data.board = match.params.id;
    putStages(data.id, data)
      .then(() => {})
      .catch(() => {
        showMsg(message);
      });
  }

  function changeType(type, index) {
    let data = step[index];
    data.type = type;
    data.board = match.params.id;
    putStages(data.id, data)
      .then(() => {
        let old = step.slice();
        if (step.findIndex((item) => item.type === type) !== -1) {
          let current = old.findIndex((item) => item.type === type);
          old[current].type = "regular";
        }
        old[index].type = type;
        setStep(old);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function removeStep(index) {
    let current = step[index].id;
    deleteStages(current)
      .then(() => {
        showMsg(messageSuccess);
        setModal(false);
        let oldStep = step.slice();
        oldStep.splice(index, 1);
        setStep(oldStep);
        setEdit(-1);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  const handleNew = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newStep = { ...newOne };
    newStep.title = event.target.value;
    setNewOne(newStep);
  };

  function changeTypeNew(type) {
    let newStep = { ...newOne };
    newStep.type = type;
    setNewOne(newStep);
  }

  function setAddNewStep() {
    setAdd(true);
    newTitle.current!.focus();
  }

  function createNew() {
    let data = {
      board: match.params.id,
      editMode: true,
      type: newOne.type,
      title: newOne.title,
    };
    postStages(data)
      .then((res) => {
        let stages = step.slice();
        stages.push(res.data);
        setStep(stages);
        showMsg(create);
        setAdd(false);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  const onDrop = (stage, newPosition) => {
    //Если не переместили, ничего не меняем
    if (stage.position === newPosition) {
      return null;
    }

    let newSteps = step.slice();
    let stepIndex = newSteps.findIndex((item) => item.id === stage.id);
    newSteps[stepIndex].position = newPosition;

    /* 
        Мы изменили позицию элемента, который переместили, 
        теперь нужно переписать позицию всех элементов, на которые повлияла сортировка.
        Для этого в циклах перебираем все элементы между новой и старой позицией, 
        исключая элемент, который мы передвигаем
        */
    //TODO Нет перемещения на первую позицию справа налево
    if (stage.position < newPosition && newSteps.length !== newPosition + 1) {
      //Если переместили слева на право и не в конец
      for (let i = stepIndex + 1; i <= newPosition + 1; i++) {
        newSteps[i].position -= 1;
      }
    } else if (newSteps.length === newPosition + 1) {
      //Если переместили слева на право в конец
      for (let i = stepIndex + 1; i <= newPosition; i++) {
        newSteps[i].position -= 1;
      }
    } else {
      //Если переместили справа на лево
      for (let i = newPosition + 1; i <= stepIndex; i++) {
        newSteps[i].position += 1;
      }
    }
    newSteps = newSteps.sort((a, b) =>
      a.position > b.position ? 1 : b.position > a.position ? -1 : 0
    );
    setStep(newSteps);
    changePosition(newSteps[stepIndex]);
  };

  function changePosition(newStep) {
    let data = newStep;
    (data.board = match.params.id),
      putStages(newStep.id, data)
        .then(() => {
          showMsg(changeSuccess);
        })
        .catch(() => {
          showMsg(changeError);
        });
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={st.grid}>
        <div className={st.wrap}>
          {step.map((item, index) => (
            <Step
              key={item.id}
              item={item}
              index={index}
              edit={edit}
              setEdit={setEdit}
              changeStep={changeStep}
              changeType={changeType}
              handleColumns={handleColumns}
              setModal={setModal}
              onDrop={onDrop}
            />
          ))}
          <div
            className={st.card + " " + (!add ? st.card_disactive : "")}
            onClick={() => createNew()}
          >
            <div
              className={st.overlay + " " + (add && st.overlay_active)}
              onClick={() => createNew()}
            />
            <div className={st.step + " " + (add && st.step_active)}>
              <div className={st.head}>
                <div className={st.head__empty} />
                <input
                  className={st.name}
                  value={newOne.title}
                  ref={newTitle}
                  onChange={(e) => handleNew(e)}
                />
                <div className={st.icon}>
                  <SVG
                    className={
                      st.img +
                      " " +
                      (newOne.type === "first" ? st.img_active : "")
                    }
                    src={startImg}
                  />
                  <SVG
                    className={
                      st.img +
                      " " +
                      (newOne.type === "last" ? st.img_active : "")
                    }
                    src={finishImg}
                  />
                </div>
              </div>
              <div className={st.container}>
                <div
                  className={st.positions + " " + (add && st.positions_active)}
                >
                  <div className={st.positions__wrap}>
                    <SVG
                      src={startImg}
                      className={st.positions__img}
                      onClick={() => changeTypeNew("first")}
                    />
                    <div className={st.positions__title}>старт</div>
                  </div>
                  <div className={st.positions__wrap}>
                    <SVG
                      src={finishImg}
                      className={st.positions__img}
                      onClick={() => changeTypeNew("last")}
                    />
                    <div className={st.positions__title}>финиш</div>
                  </div>
                </div>
                <div className={st.container__wrap}>
                  <SVG src={leftImg} className={st.left} />
                  <SVG src={rightImg} className={st.left} />
                </div>
                <div
                  className={st.remove + " " + (add && st.remove_active)}
                  onClick={() => setModal(true)}
                >
                  Удалить этап
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={st.addBtn} onClick={() => setAddNewStep()}>
          <SVG src={plusImg} />
        </div>
        {modal && (
          <Modal
            isOpen={modal}
            className={st.modal}
            overlayClassName={st.modal__overlay}
            appElement={document.getElementById("root")}
            onRequestClose={() => setModal(false)}
          >
            <div className={st.modal__title}>
              Вы действительно хотите удалить этап &quot;
              <span>{step[edit].title}</span>&quot;?
            </div>
            <div className={st.modal__wrap}>
              <div className={st.modal__btn} onClick={() => removeStep(edit)}>
                Да
              </div>
              <div className={st.modal__btn} onClick={() => setModal(false)}>
                Нет
              </div>
            </div>
          </Modal>
        )}
      </div>
    </DndProvider>
  );
};

export default Steps;

import React from "react";

import st from "./ProductCard.module.scss";

type Product = {
  id: string;
};

interface Props {
  product: Product;
}

const ProductCard: React.FC<Props> = ({ product }) => {
  const getAttribute = (attribute) => {
    if (attribute.code === "color" || attribute.code === "col") {
      return (
        <div className={st.color}>
          Цвет:
          <div
            style={{
              backgroundColor: `#${attribute.value.toLowerCase()}`,
              width: "20px",
              height: "10px",
              borderRadius: "4px",
              marginLeft: "10px",
            }}
          />
        </div>
      );
    }

    return (
      <div>
        {attribute.attribute}: {attribute.value}
      </div>
    );
  };
  return (
    <div className={st.card}>
      <div>Id: {product.id}</div>
      <div>{product.title}</div>
      {product.attributes.map((attribute) => (
        <React.Fragment key={attribute.id}>
          {getAttribute(attribute)}
        </React.Fragment>
      ))}
      <div>Количество: {product.count}</div>
      <div>Цена: {product.price}</div>
    </div>
  );
};

export default ProductCard;

import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import {openMenuAction} from 'library/common/actions/shellActions';
import {logOutAction} from 'library/common/actions/user';
import { isMenuOpen, addBtn } from 'library/common/selectors/shellSelectors';
import { getUserInfo } from 'library/common/selectors/user';
import {showMsg} from 'library/common/actions/shellActions';

import Header from './Header';

const mapStateToProps = (store: RootState) => ({
	isMenuOpen: isMenuOpen(store),
	user: getUserInfo(store),
	addBtn: addBtn(store)
});

export default connect(mapStateToProps,{
	openMenuAction,
	logOutAction,
	showMsg
})(Header);
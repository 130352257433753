import {connect} from 'react-redux';

import { showMsg } from 'library/common/actions/shellActions';
import { cardInfoAction, openCardModalAction } from 'library/common/actions/orderActions'

import ClearBasket from './ClearBasket';

export default connect(null, {
    showMsg,
    cardInfoAction,
    openCardModalAction
})(ClearBasket);
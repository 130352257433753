/* eslint-disable no-unused-vars */
import React from 'react';
import Modal from 'react-modal';
import { clearBasket } from 'library/services/boardsApi';

import st from './index.module.scss';

interface IClearBasket {
    open: boolean,
    closeModal: () => void,
    showMsg: (data: any) => void,
    cardInfoAction: (data: any) => void
}

const ClearBasket: React.FC<IClearBasket> = ({open, closeModal, showMsg, cardInfoAction}: IClearBasket) => {

    const message = {errorColor: false, msg: 'Корзина очищена', show: true};
    const messageError = {errorColor: true, msg: 'Не удалось очистить корзину', show: true};
    const newCard = {id:0, board_id: 0}

    function removeCard() {
        clearBasket()
        .then(() => {
            showMsg(message)
            cardInfoAction(newCard)
            closeModal()
        })
        .catch(() => {
            showMsg(messageError)
        })
    }

    return (
        <Modal isOpen={open}
        className={st.modal}
         overlayClassName={st.overlay}
         appElement={document.getElementById('root')}
         onRequestClose={() => closeModal()}
         >  
        <div className={st.grid}>Очистить содержимое корзины?</div>
        <div className={st.wrap}>
            <div className={st.btn} onClick={() => removeCard()}>Да</div>
            <div className={st.btn} onClick={() => closeModal()}>Нет</div>
        </div>
        </Modal>
    )
}

export default ClearBasket;
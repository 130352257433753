import styles from './index.module.scss';

const CardPlaceholder = () => {
    return (
        <div>
            <div className={styles.card}>
                <div className={styles.card__bcg}></div>
                <div className={styles.title}></div>
                <div className={styles.number}></div>
            </div>
        </div>
    )
}

export default CardPlaceholder;
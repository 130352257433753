import {all, fork} from 'redux-saga/effects';

import testSaga from '../library/common/sagas/testSaga';
import authSaga from '../library/common/sagas/authSaga';
import shellSaga from '../library/common/sagas/shellSaga';
import orderSaga from '../library/common/sagas/orderSaga';

export default function* coreSaga() {
	yield all([
		fork(testSaga),
		fork(authSaga),
		fork(shellSaga),
		fork(orderSaga)
	]);
}

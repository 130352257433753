import {takeLatest} from 'redux-saga/effects';

//import {testAction} from '../actions/testActions';
import {ShellTypes} from '../types/shellTypes';

function* menuActionSaga() {
	yield true;
}

export default function* watchTest() {
	yield takeLatest(ShellTypes.HANDLE_MENU_ACTION, menuActionSaga);
}

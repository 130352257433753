/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, Switch, Route } from "react-router-dom";
import { history } from "core/store/configureStore";
import { getMembers } from "library/services/boardsApi";
import Member from "./Frames/Member";
import NewMember from "./Frames/NewMember";

import st from "./index.module.scss";

interface IMembers {
  showMsg: (data: any) => void;
  globalAddAction: (data: any) => void;
}

const Members: React.FC<IMembers> = ({
  showMsg,
  globalAddAction,
}: IMembers) => {
  const [users, setUsers] = useState<any[]>([]);
  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };

  const path = history.location.pathname;
  const idUser = path.substr(path.lastIndexOf("/") + 1);

  useEffect(() => {
    getUsers();
    globalAddAction(addMember);
  }, []);

  useEffect(() => {
    getUsers();
    globalAddAction(addMember);
  }, [history.location.pathname]);

  function addMember() {
    history.push("/members/create");
  }

  function getUsers() {
    getMembers()
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  return (
    <div className={st.grid}>
      <div className={st.sidebar}>
        <div className={st.member}>
          {users.map((user) => (
            <Link
              className={
                st.member__link +
                " " +
                (idUser == user.id ? st.member__link_active : "")
              }
              key={user.id}
              to={"/members/" + user.id.toString()}
            >
              {user.logo_url ? (
                <img className={st.img} src={user.logo_url} alt="" />
              ) : (
                <div className={st.img} />
              )}
              <div className={st.title}>{user.name}</div>
            </Link>
          ))}
        </div>
      </div>
      <Switch>
        <Route path="/members/create" component={NewMember} />
        <Route path="/members/:id" component={Member} />
      </Switch>
    </div>
  );
};

export default Members;

/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';

import { getCardHistory } from 'library/services/boardsApi';

import st from './index.module.scss';

interface IHistoryCard {
    open: boolean,
    closeModal: () => void,
    card: {id: number},
    showMsg: (data: any) => void
}

const HistoryCard: React.FC<IHistoryCard> = ({open, closeModal, card, showMsg}:IHistoryCard) => {

    const [history, setHistory] = useState<any[]>([]);
    const message = {errorColor: true, msg: 'Не удалось получить историю', show: true};

    useEffect(() => {
        getHistory();
    },[]);

    function getHistory(){
        getCardHistory(card.id).then(res => {
            setHistory(res.data)
        }).catch( () => {
            showMsg(message)
        } )
    }

    function handleDate(date) {
        let time = new Date(date);
        let month = time.getMonth() + 1;
        let day = time.getDay();
        let year = time.getFullYear();
        let hour = time.getHours();
        let minutes = time.getMinutes();
        return day+"."+month+"."+year+"   "+hour+":"+minutes

    }

 return (
    <Modal isOpen={open}
           className={st.modal}
            overlayClassName={st.overlay}
            appElement={document.getElementById('root')}
            onRequestClose={() => closeModal()}
            >   
            <div className={st.grid}>
                <div className={st.date}>Дата</div>
                <div className={st.date}>Участник</div>
                <div className={st.date}>Действие</div>
            </div>
            {history.map((item) => 
             <div className={st.notes} key={item.id}>
                <div className={st.note}>{handleDate(item.timestamp)}</div>
                <div className={st.name}>{item.user}</div>
                <div className={st.action}>{item.type}</div>
            </div>
            )}
           
    </Modal>
 )
}

export default HistoryCard;
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import { getBasket } from 'library/services/boardsApi';

import ClearBasket from 'library/common/components/ClearBasketModal';

import styles from './index.module.scss';

interface IBasket {
    showMsg: (data: any) => void,
    openCardBasketAction: (id: void) => void,
    getAvailableModulesAction: () => void,
    card: {id: number}
}

const Basket: React.FC<IBasket> = ({showMsg, openCardBasketAction, getAvailableModulesAction, card }: IBasket) => {

    const [cards, setCard] = useState<any[]>([]);
    const [clear, setClear] = useState<boolean>(false);
    const message = {errorColor: true, msg: 'Не удалось получить содержимое корзины', show: true};


    useEffect(() => {
        getBasketContent()
        getAvailableModulesAction()
    },[])

    useEffect(() => {
        getBasketContent()
        getAvailableModulesAction()
    }, [card])

    function getBasketContent(){
        getBasket().then(res => {
            setCard(res.data)
        }).catch(() => {
            showMsg(message)
        })
    }

    return(
        <div className={styles.grid}>
            <div className={styles.step}>
                <div className={styles.head}>
                    <div className={styles.counter}>{cards.length}</div>
                    <div className={styles.name}>Корзина</div>
                    <div className={styles.counter}/>
                </div>
                <div className={styles.wrap}>
                    {cards.length === 0 ? <div className={styles.empty}>Корзина пуста</div>
                    :
                    cards.map(card => 
                    <div className={styles.card} style={{backgroundColor: '#'+card.color}} key={card.id} onClick={() => openCardBasketAction(card.id)}>
                        <div className={styles.card__bcg}/>
                        <div className={styles.title}>{card.title}</div>
                        <div className={styles.number}></div>
                    </div>
                    )}
                </div>
                <div className={styles.foot}>
                    {
                        cards.length > 0 && <div className={styles.delete} onClick={() => setClear(true)}>Очистить корзину</div>
                    }
                    
                </div>
            </div>
            <ClearBasket open={clear} closeModal={() => setClear(false)} />
        </div>
    )
}

export default Basket;
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  getDeliveryPaks,
  deliveryInfo,
  getDeliveryCompanies,
} from "library/services/boardsApi";
import SVG from "react-inlinesvg";
import Input from "library/common/components/Input";
import DropDown from "library/common/components/DropDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import QrScanner from "./Frames/QrScanner";

import st from "./index.module.scss";

import qr from "resources/img/qr.svg";
import printer from "resources/img/printer.svg";

interface IDelivery {
  showMsg: (data: any) => void;
  id: number;
}
const Delivery: React.FC<IDelivery> = ({ showMsg, id }: IDelivery) => {
  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };

  const [qrScannerModal, setQrScannerModal] = useState<boolean>(false);
  const [info, setInfo] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(true);
  const [startDate, setStartDate] = useState(new Date());
  const [companies, setCompanies] = useState<any[]>([]);
  const [currentCompany, setCurrentCompany] = useState<any>({
    id: 0,
    title: "",
  });

  useEffect(() => {
    getOrderInfo();
    getCompanies();
  }, []);

  function getOrderInfo() {
    getDeliveryPaks(id)
      .then((res) => {
        setInfo(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function getCompanies() {
    getDeliveryCompanies()
      .then((res) => {
        setCompanies(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  const changeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let newInfo = { ...info };
    newInfo[field] = event.target.value;
    setInfo(newInfo);
  };

  function setToServer() {
    let newInfo = { ...info };
    delete newInfo.shipped_packages;
    deliveryInfo(info.id, newInfo)
      .then(() => {
        setDisabled(true);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function updateShipped(data) {
    let newInfo = { ...info };
    newInfo.shipped_packages.push(data);
    setInfo(newInfo);
  }

  return (
    <div className={st.grid}>
      <div className={st.title}>Доставка</div>
      <div className={st.wrap}>
        <div className={st.line}>
          <div className={st.name}>Траспортн. комп.</div>
          <DropDown
            options={companies}
            defaultValue={currentCompany}
            disabled={disabled}
            changeValue={setCurrentCompany}
          />
        </div>
        <div className={st.line}>
          <div className={st.name}>Транс. нак. №</div>
          <Input
            width={"422"}
            height={"36"}
            bcg={"f5f5f5"}
            value={info.number}
            disabled={disabled}
            onChange={(e) => changeInput(e, "number")}
          />
        </div>
        <div className={st.line}>
          <div className={st.name}>Дата отгрузки</div>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className={
              st.datepicker + " " + (!disabled ? st.datepicker_active : "")
            }
            disabled={disabled}
          />
        </div>
        <div className={st.line}>
          <div className={st.name}>Товар</div>
          <div className={st.btn} onClick={() => setQrScannerModal(true)}>
            <SVG src={qr} />
            <div className={st.btn__text}>Сканировать QR</div>
          </div>
        </div>
        <div className={st.line}>
          <div className={st.name}>Город</div>
          <Input
            width={"422"}
            height={"36"}
            bcg={"fdfbfb"}
            value={info.city}
            disabled={true}
            onChange={(e) => changeInput(e, "city")}
          />
        </div>
        <div className={st.line}>
          <div className={st.name}>Улица</div>
          <Input
            width={"422"}
            height={"36"}
            bcg={"fdfbfb"}
            value={info.delivery_street}
            disabled={true}
            onChange={(e) => changeInput(e, "delivery_street")}
          />
        </div>
        <div className={st.line}>
          <div className={st.name}>Дом</div>
          <Input
            width={"422"}
            height={"36"}
            bcg={"fdfbfb"}
            value={info.delivery_build}
            disabled={true}
            onChange={(e) => changeInput(e, "delivery_build")}
          />
        </div>
        <div className={st.line}>
          <div className={st.name}>Получатель</div>
          <Input
            width={"422"}
            height={"36"}
            bcg={"fdfbfb"}
            value={info.recipient}
            disabled={true}
            onChange={(e) => changeInput(e, "recipient")}
          />
        </div>
        <div className={st.line}>
          <div className={st.name}>Номер телефона</div>
          <Input
            width={"422"}
            height={"36"}
            bcg={"fdfbfb"}
            value={info.phone}
            disabled={true}
            onChange={(e) => changeInput(e, "phone")}
          />
        </div>
        <div className={st.line}>
          <div className={st.name}>Паспорт</div>
          <Input
            width={"422"}
            height={"36"}
            bcg={"fdfbfb"}
            value={info.passport}
            disabled={true}
            onChange={(e) => changeInput(e, "passport")}
          />
        </div>
      </div>
      <div className={st.foot}>
        <div className={st.btn}>{/* <SVG src={printer} alt='' /> */}</div>
        {disabled ? (
          <div className={st.btn} onClick={() => setDisabled(!disabled)}>
            Редактировать
          </div>
        ) : (
          <div className={st.btn} onClick={() => setToServer()}>
            Сохранить
          </div>
        )}
      </div>
      {qrScannerModal && (
        <QrScanner
          qrModal={qrScannerModal}
          shipped={info.shipped_packages}
          id={id}
          closeModal={() => setQrScannerModal(false)}
          updateShipped={updateShipped}
        />
      )}
    </div>
  );
};

export default Delivery;

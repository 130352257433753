// import axios from 'axios';

// export const fetch = axios.create({
// 	baseURL: 'http://localhost:3000/api/v1/',
// });

import axios from "axios";

// let API_BASE_URL;
// let url = window.location.href;

// if (url.indexOf("localhost:3000") !== -1) {
//   API_BASE_URL = "https://region.nicecode.biz/api/v1";
// } else if (url.indexOf("region.nicecode.biz") !== -1) {
//   API_BASE_URL = "https://region.nicecode.biz/api/v1";
// } else {
//   API_BASE_URL = "https://" + window.location.host + "/api/v1";
// }

export const fetch = axios.create({
  headers: {
    "Access-Control-Allow-Credentials": true,
  },
});

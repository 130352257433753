import { connect } from 'react-redux';

import { RootState } from 'core/store/configureStore';

import {logOutAction, setUserDataAction} from 'library/common/actions/user';
import {getEmail, getIsAuthenticated} from 'library/common/selectors/user';

import ProtectedLayout from './ProtectedLayout';

const mapStateToProps = (store: RootState) => ({
    isAuthenticated: getIsAuthenticated(store),
    email: getEmail(store)
})

export default connect(mapStateToProps, {
    logOutAction,
    setUserDataAction
})(ProtectedLayout);

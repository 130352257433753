import React from 'react';
import {Redirect} from 'react-router-dom';

import {requestHandShake} from 'library/services/userApi';

import Card from 'library/common/components/CardModal';

export interface IProps {
	children: any;
	isAuthenticated: boolean;
	logOutAction: () => void;
	setUserDataAction: Function;
	email: string;
}

export default class ProtectedLayout extends React.Component<IProps> {
	public componentDidMount(){
		if(this.props.isAuthenticated){
			requestHandShake().then((res) => {
					this.props.setUserDataAction(res.data)
				})
		}
	}
	public render() {
		const {children, isAuthenticated} = this.props;
		
		if (!isAuthenticated) {
			return <Redirect to='/login' />
		}
		return (
			<React.Fragment>
					{children}
			<Card />
			</React.Fragment>
		);
	}
}

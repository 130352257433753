/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { Link, Switch, Route } from "react-router-dom";
import { history } from "core/store/configureStore";
import {
  getСlients,
  getClientsByTag,
  getGroups,
  getTags,
} from "library/services/clientsApi";
import Client from "./Frames/Client";

import st from "./index.module.scss";

import plus from "resources/img/add.svg";
import arrow from "resources/img/arrow.svg";

interface IClients {
  showMsg: (data: any) => void;
}

const Clients: React.FC<IClients> = ({ showMsg }: IClients) => {
  const [tags, setTags] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [handleTags, setHandleTags] = useState<boolean>(true);
  const [groups, setGroups] = useState<any[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const message = {
    errorColor: true,
    msg: "Не удалось загрузить клиентов",
    show: true,
  };
  const messageGroups = {
    errorColor: true,
    msg: "Не удалось загрузить группы",
    show: true,
  };
  const messageTags = {
    errorColor: true,
    msg: "Не удалось загрузить теги",
    show: true,
  };

  const path = history.location.pathname;
  const idUser = path.substr(path.lastIndexOf("/") + 1);

  useEffect(() => {
    getMyTags();
    getMyGroups();
  }, []);

  useEffect(() => {
    getClients();
  }, [history.location.pathname]);

  function getClients() {
    getСlients()
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function getMyGroups() {
    getGroups()
      .then((res) => {
        setGroups(res.data);
      })
      .catch(() => {
        showMsg(messageGroups);
      });
  }

  function getMyTags() {
    getTags()
      .then((res) => {
        setTags(res.data);
      })
      .catch(() => {
        showMsg(messageTags);
      });
  }

  const getClientsByTagId = (tag) => {
    getClientsByTag(tag)
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  };

  function handleColor(id: number) {
    if (tags.length !== 0 && id !== null) {
      let colors = tags.slice();
      let index = colors.findIndex((color) => color.id === id);
      return colors[index].hex;
    }
  }

  return (
    <div className={st.grid}>
      <div
        className={st.filters + " " + (showFilters || st.filters__collapsed)}
      >
        <Link className={st.all} to={"/clients"}>
          <div className={st.all__name}>Все клиенты</div>
          <div className={st.all__count}>{users.length}</div>
        </Link>
        <div className={st.groups}>
          <div className={st.groups__name}>Группы</div>
          {groups.map((group) => (
            <div key={group.id}>{group.id}</div>
          ))}
          <div className={st.groups__plus}>
            <SVG src={plus} className={st.groups__plus_img} />
          </div>
        </div>
        <div className={st.tags}>
          <div
            className={st.tags__wrap}
            onClick={() => setHandleTags(!handleTags)}
          >
            <div className={st.tags__name}>Теги</div>
            <SVG
              src={arrow}
              className={
                st.tags__arrow + " " + (handleTags ? "" : st.tags__arrow_rotate)
              }
            />
          </div>
          <div
            className={
              st.tags__colors + " " + (handleTags ? "" : st.tags__colors_hidden)
            }
          >
            {tags.map((tag) => (
              <div
                className={st.color}
                key={tag.id}
                onClick={() => getClientsByTagId(tag.id)}
              >
                <div className={st.color__name}>{tag.title}</div>
                <div
                  className={st.color__circle}
                  style={{ backgroundColor: "#" + tag.hex }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={st.collapse + " " + (showFilters || st.hidden)}
        onClick={() => setShowFilters(!showFilters)}
      >
        <SVG src={arrow} className={st.collapse__arrow} />
      </div>
      <div
        className={st.expand + " " + (showFilters && st.hidden)}
        onClick={() => setShowFilters(!showFilters)}
      >
        <SVG src={arrow} className={st.expand__arrow} />
      </div>
      <div className={st.sidebar}>
        <div className={st.client}>
          {users.map((user) => (
            <Link
              className={
                st.client__link +
                " " +
                (idUser == user.id ? st.member__link_active : "")
              }
              key={user.id}
              to={"/clients/" + user.id.toString()}
            >
              <div
                className={st.client__tag}
                style={{ backgroundColor: "#" + handleColor(user.tag) }}
              />
              <div className={st.title}>{user.title}</div>
              <div className={st.type}>{user.type_face}</div>
            </Link>
          ))}
        </div>
      </div>
      <Switch>
        <Route path="/clients/:id" render={() => <Client tags={tags} />} />
      </Switch>
    </div>
  );
};

export default Clients;

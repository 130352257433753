import {Route, Switch, Redirect} from 'react-router-dom';

import Auth from 'pages/Auth';
import Header from 'library/common/components/Header';
import ProtectedLayout from 'library/common/components/Layouts/ProtectedLayout';
import Shell from 'library/common/components/Layouts/Shell';
import RightBar from 'library/common/components/RightBar';
import Boards from 'pages/Boards';
import Members from 'pages/Members';
import Clients from 'pages/Clients';
import BoardItem from 'pages/Boards/Frames/BoardItem';
import ResetPass from 'pages/Auth/Frames/ResetPass';
import ErrorComponent from 'library/common/components/Error';
import Basket from 'pages/Basket';
import Personal from 'pages/Personal';
import SettingsBoard from 'pages/Boards/Frames/SettingsBoard';

const Routes = () => (
	<div>
	<Switch>
		<Route exact path='/login' component={Auth} />
		<Route exact path='/forgot-pass' component={ResetPass} />
		<Route
			path='/'
			render={() => (
				<ProtectedLayout>
					<Route path="*" component={Header} />
					<Route path="*" component={RightBar} />
					<Shell>
						<Switch>
							<Redirect exact from="/" to="/boards" />
							<Route exact path="/boards" component={Boards} />
							<Route exact path="/boards/:id" component={BoardItem} />
							<Route path="/boards/:id/stages" component={SettingsBoard} />
							<Route path="/members" component={Members} />
							<Route path="/clients" component={Clients} />
							<Route exact path="/basket" component={Basket} />
							<Route exact path="/personal" component={Personal} />
						</Switch>
					</Shell>
				</ProtectedLayout>
			)}
		/>
	</Switch>
	<Route path="*" component={ErrorComponent} />
	</div>
);

export default Routes;

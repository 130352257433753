/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  getSubProcess,
  getBoardsList,
  postSubProcess,
  deleteSubProcess,
} from "library/services/boardsApi";

import st from "./index.module.scss";

import startImg from "resources/img/stepStart.svg";
import finishImg from "resources/img/stepFinish.svg";
import lifterImg from "resources/img/lifter.svg";
import menuImg from "resources/img/menu.svg";
import lkImg from "resources/img/lk.svg";
import plusImg from "resources/img/add.svg";

interface IFloors {
  match: any;
  showMsg: (data: any) => void;
}

const Floors: React.FC<IFloors> = ({ match, showMsg }: IFloors) => {
  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };
  const messageSuccess = {
    errorColor: false,
    msg: "Переход добавлен",
    show: true,
  };

  const [boards, setBoards] = useState<any[]>([]);
  const [process, setProcess] = useState<any[]>([]);
  const [currentCard, setCurrentCard] = useState<any>({});
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    getAllBoards();
  }, []);

  useEffect(() => {
    getAllProcess();
  }, []);

  function getAllProcess() {
    getSubProcess(match.params.id)
      .then((res) => {
        setProcess(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function getAllBoards() {
    getBoardsList(match.params.id)
      .then((res) => {
        setBoards(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function startEdit(id) {
    setCurrentCard(id);
    setEdit(!edit);
  }

  function changeTransition(item) {}

  function chooseBoard(id) {
    let data = {
      column_from: currentCard,
      column_to: id,
    };
    postSubProcess(data)
      .then((res) => {
        showMsg(messageSuccess);
        let ways = process.slice();
        let index = ways.findIndex((item) => item.id === currentCard);
        ways[index].subprocess = res.data;
        setProcess(ways);
        setEdit(false);
        setCurrentCard({});
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function removeProcess(id, idProc) {
    let data = {
      column_from: id,
      column_to: idProc,
    };
    deleteSubProcess(data)
      .then(() => {
        let ways = process.slice();
        let index = ways.findIndex((item) => item.id === id);
        ways[index].subprocess = null;
        setProcess(ways);
        setEdit(false);
        setCurrentCard({});
      })
      .catch(() => {
        showMsg(message);
      });
  }

  return (
    <div className={st.grid}>
      <div className={st.wrap}>
        {process.map((proc) => (
          <div
            className={st.step}
            key={proc.id}
            onClick={() => startEdit(proc.id)}
          >
            <div className={st.head}>
              <div className={st.head__empty} />
              <div className={st.name}>{proc.title}</div>
              <div className={st.icon}>
                <SVG
                  className={
                    st.img + " " + (proc.type === "first" ? st.img_active : "")
                  }
                  src={startImg}
                />
                <SVG
                  className={
                    st.img + " " + (proc.type === "last" ? st.img_active : "")
                  }
                  src={finishImg}
                />
              </div>
            </div>
            <div className={st.container}>
              {(currentCard === proc.id || proc.subprocess) && (
                <SVG src={lifterImg} className={st.lifter} />
              )}
              {currentCard === proc.id &&
                boards.map((board) => (
                  <div key={board.id} className={st.boards}>
                    <div
                      className={
                        st.boards__title +
                        " " +
                        (edit &&
                        proc.subprocess !== null &&
                        board.id === proc.subprocess.id
                          ? st.boards__title_active
                          : "")
                      }
                    >
                      {board.title}
                    </div>
                    <div className={st.diagram__box}>
                      <div className={st.diagram__menu}>
                        <div className={st.diagram__left}>
                          <div className={st.diagram__img}>
                            <SVG src={menuImg} />
                          </div>
                          <div className={st.diagram__img}>
                            <SVG src={plusImg} />
                          </div>
                        </div>
                        <SVG src={lkImg} className={st.diagram__lk} />
                      </div>
                      <div className={st.diagram__container}>
                        {board.columns.map((litleBoard) => (
                          <div
                            className={
                              st.diagram__stage +
                              " " +
                              (proc.subprocess &&
                              proc.subprocess.target_column === litleBoard.id
                                ? st.diagram__stage_active
                                : "")
                            }
                            key={litleBoard.id}
                            onClick={() => chooseBoard(litleBoard.id)}
                          >
                            <div className={st.diagram__head}>
                              <div className={st.diagram__subtitle}>
                                {litleBoard.title}
                              </div>
                            </div>
                            <div className={st.diagram__btn} />
                            <div className={st.diagram__bigTitle}>
                              {litleBoard.title}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              {proc.subprocess && currentCard !== proc.id && (
                <div key={proc.id} className={st.preview}>
                  <div className={st.preview__title}>
                    {proc.subprocess.title}
                  </div>
                  <div className={st.preview__box}>
                    <div className={st.preview__menu}>
                      <div className={st.preview__left}>
                        <div className={st.preview__img}>
                          <SVG src={menuImg} />
                        </div>
                        <div className={st.preview__img}>
                          <SVG src={plusImg} />
                        </div>
                      </div>
                      <SVG src={lkImg} className={st.preview__lk} />
                    </div>
                    <div className={st.preview__container}>
                      {proc.subprocess.columns.map((item) => (
                        <div className={st.preview__stage} key={item.id}>
                          <div className={st.preview__head}>
                            <div className={st.preview__subtitle}>
                              {item.title}
                            </div>
                          </div>
                          <div className={st.preview__btn} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {proc.subprocess && (
                <div
                  className={st.remove}
                  onClick={() =>
                    removeProcess(proc.id, proc.subprocess.target_column)
                  }
                >
                  Отменить переход
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Floors;

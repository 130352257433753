import { fetch } from "../utilities/fetch";

export const getBoards = () => fetch.get("/api/v1/boards/");

export const getBoard = (id) => fetch.get(`/api/v1/boards/${id}/`);

export const createBoard = () => fetch.post("/api/v1/boards/");

export const getMyModules = () => fetch.get("/api/v1/modules/");

export const myModules = () => fetch.get(`/api/v1/modules/for_me/`);

//export const myModulesForBoard = (id) => fetch.get(`/boards/${id}/my_settings/`);

export const myModulesForBoard = (id) =>
  fetch.get(`/api/v1/boards/${id}/modules/`);

export const getAccessModules = (id) =>
  fetch.get(`/api/v1/admin/boards/${id}/modules/`);

export const postAccessModules = (id, data) =>
  fetch.post(`/api/v1/admin/boards/${id}/modules/`, data);

export const deleteAccessModules = (id, data) =>
  fetch.delete(`/api/v1/admin/boards/${id}/modules/`, { data });

export const getCard = (id) => fetch.get(`/api/v1/cards/${id}/`);

export const getCardBasket = (id) => fetch.get(`/api/v1/cards/basket/${id}/`);

export const changeCard = (id, data) =>
  fetch.patch(`/api/v1/cards/${id}/`, data);

export const deleteCard = (id) => fetch.delete(`/api/v1/cards/${id}/`);

export const purgeCard = (id) => fetch.delete(`/api/v1/cards/basket/${id}/`);

export const restoreCard = (id, data) =>
  fetch.put(`/api/v1/cards/basket/${id}/`, data);

export const getCardTZ = (id) =>
  fetch.get(`/api/v1/cards/${id}/tech_spec/?format=json`);

export const putCardTZ = (id, data) =>
  fetch.put(`/api/v1/tech_spec/${id}/`, data);

export const getCardNotes = (id) => fetch.get(`/api/v1/cards/${id}/notes/`);

export const getCardHistory = (id) => fetch.get(`/api/v1/cards/${id}/history/`);

export const getBasket = () => fetch.get(`/api/v1/cards/basket/`);

export const clearBasket = () => fetch.delete(`/api/v1/cards/basket/`);

export const getMembers = () => fetch.get(`/api/v1/accounts/?format=json`);

export const getMember = (id) => fetch.get(`/api/v1/accounts/${id}/`);

export const addMember = (data) => fetch.post(`/api/v1accounts/`, data);

export const changeMember = (id, data) =>
  fetch.patch(`/api/v1/accounts/${id}/`, data);

export const deleteMember = (id) => fetch.delete(`/api/v1/accounts/${id}/`);

export const addAvatar = (id, data) =>
  fetch.patch(`/api/v1/accounts/${id}/avatar/`, data);

export const getBoardsSettings = (id) =>
  fetch.get(`/api/v1/admin/boards/${id}/`);

export const getStages = (id) =>
  fetch.get(`/api/v1/admin/boards/${id}/stages/`);

export const putStages = (id, data) =>
  fetch.put(`/api/v1/admin/stages/${id}/`, data);

export const deleteStages = (id) => fetch.delete(`/api/v1/admin/stages/${id}/`);

export const getTransitions = (id) =>
  fetch.get(`/api/v1/admin/boards/${id}/transitions/`);

export const postTransition = (data) =>
  fetch.post(`/api/v1/admin/transitions/`, data);

export const deleteTransition = (data) =>
  fetch.delete(`/api/v1/admin/transitions/`, { data });

export const getSubProcess = (id) =>
  fetch.get(`/api/v1/admin/boards/${id}/subprocess/`);

export const postSubProcess = (data) =>
  fetch.post(`/api/v1/admin/subprocess/`, data);

export const deleteSubProcess = (data) =>
  fetch.delete(`/api/v1/admin/subprocess/`, { data });

export const getBoardsList = (id) => fetch.get(`/api/v1/boards/?exclude=${id}`);

export const getMembersAccounts = (id) =>
  fetch.get(`/api/v1/admin/boards/${id}/accounts/`);

export const getAllAccounts = () => fetch.get(`/api/v1/accounts/`);

export const postMembers = (data) =>
  fetch.post(`/api/v1/admin/accounts/`, data);

export const deleteMembers = (data) =>
  fetch.delete(`/api/v1/admin/accounts/`, { data });

export const postStages = (data) => fetch.post(`/api/v1/admin/stages/`, data);

export const patchBoard = (data) => fetch.patch(`/api/v1/boards/cards/`, data);

export const getSticker = (id) =>
  fetch.get(`/api/v1/production/card/${id}/produced_package/`);

export const postSticker = (data) =>
  fetch.post(`/api/v1/production/produced_package/`, data);

export const getDeliveryPaks = (id) =>
  fetch.get(`/api/v1/delivery/order/${id}/by_card/`);

export const deliveryInfo = (id, data) =>
  fetch.patch(`/api/v1/delivery/order/${id}/`, data);

export const postDeliveryPaks = (data) =>
  fetch.post(`/api/v1/delivery/shipped_package/`, data);

export const getDeliveryCompanies = () =>
  fetch.get(`/api/v1/delivery/company/`);

// export const setNote = (data) => fetch.post(`/notes/`, data);
export const setNewNote = (data) => fetch.post(`/api/v1/notes/`, data);
export const deleteNote = (id) => fetch.delete(`/api/v1/notes/${id}/`);
export const getActiveNote = (id, idCard) =>
  fetch.get(`/api/v1/cards/${idCard}/notes/${id}/`);
export const sendUpdateNoteText = (id, data) =>
  fetch.patch(`/api/v1/notes/${id}/`, data);
export const addFile = (id, data) =>
  fetch.post(`/api/v1/notes/${id}/files/`, data);
export const removeFile = (idNote, idFile) =>
  fetch.delete(`/api/v1/notes/${idNote}/files/${idFile}/`);
export const getFiles = (idNote) => fetch.get(`/api/v1/notes/${idNote}/files/`);

//TODO добавить апи на получение списка компаний-перевозчиков, что-бы в модалке доставки выбирать их из выпадающего списка

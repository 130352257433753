import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom'

import SVG from 'react-inlinesvg';
import Modal from 'react-modal';
import {removeFile, getFiles, getActiveNote} from 'library/services/boardsApi'

import close from 'resources/img/close.svg';
import arrow from 'resources/img/arrow.svg';

import styles from './index.module.scss'

interface IPhotoSlider {
    activeNoteObject: any;
    files: any;
    setFiles: any;
    setActiveNoteObject: any;
    idCard: any;
}
const PhotoSlider: React.FC<IPhotoSlider> = ({activeNoteObject, files, setFiles, setActiveNoteObject, idCard}:IPhotoSlider) => {

    const sliderScrollRef = useRef<any>()
    const [showImgModal, setShowImgModal] = useState<any>(false)
    const [indexImg, setIndexImg] = useState<any>(null)

    useEffect(() => {
        getFiles(activeNoteObject.id)
        .then(res => setFiles(res.data))
    },[activeNoteObject.id])

    function scrollLeft() {
        sliderScrollRef.current.scrollLeft -= 50
    }

    function scrollRight() {
        sliderScrollRef.current.scrollLeft += 50
    }

    function showBigImage(index) {
        setIndexImg(index)
        setShowImgModal(true)
    }

    function closeModal() {
        setShowImgModal(false)
    }

    function deleteFile(indexFile) {
        removeFile(activeNoteObject.id, activeNoteObject.files?.images?.[indexFile]?.id)
        .then(() => getFiles(activeNoteObject.id))
        .then(res => {
                setFiles(res.data); 
                getActiveNote(activeNoteObject.id, idCard)
                .then(res => setActiveNoteObject(res.data))
        })
    }


    return (
        <>
            <div className={styles.sliderWrap}>
                {files.length !== 0 
                    ? <div ref={sliderScrollRef} className={styles.slider}>
                        <div className={styles.container}>
                            {files.map((url, index) => {
                                return (
                                    <div key={url.id} className={styles.wrapPhoto}>
                                        <img src={url.thumbnail} alt={''}/>
                                        <div onClick={() => showBigImage(index)} className={styles.imgOverlay}><SVG src={close} alt=""/></div>
                                        <span onClick={() => deleteFile(index)} className={styles.cross}>×</span>
                                    </div>
                                )
                            })}
                            <div onClick={scrollLeft} className={styles.leftArrow}><SVG src={arrow} alt=""/></div>
                            <div onClick={scrollRight} className={styles.rightArrow}><SVG src={arrow} alt=""/></div>
                        </div>
                    </div>
                    : null
                }
            </div>

            <Modal  isOpen={showImgModal}
                    className={styles.modal}
                    overlayClassName={styles.overlay}
                    appElement={document.getElementById('root')}
                    onRequestClose={() => closeModal()}
            >
                <img src={activeNoteObject.files?.images?.[indexImg]?.file} alt=""/>
                <span onClick={closeModal} className={styles.closeModalCross}>×</span>
                <div className={styles.downloadImg}>
                    <Link to={activeNoteObject.files?.images?.[indexImg]?.file || ''} target="_blank" download></Link>
                </div>
            </Modal> 
        </>
    )
}

export default PhotoSlider;
import {connect} from 'react-redux';
import {RootState} from 'core/store/configureStore';

import Shell from './Shell';

//import {openMenuAction} from 'library/common/actions/shellActions';
import { isMenuOpen } from 'library/common/selectors/shellSelectors';

const mapStateToProps = (store: RootState) => ({
	isMenuOpen: isMenuOpen(store)
});

export default connect(mapStateToProps)(Shell);

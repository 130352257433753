import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";

import { getBoards } from "library/services/boardsApi";

import st from "./index.module.scss";

import boardsImg from "resources/img/boards.svg";
import settingsImg from "resources/img/settings.svg";

const Boards: React.FC = () => {
  const [boardsList, setBoardsList] = useState<any[]>([]);

  useEffect(() => {
    getBoards()
      .then((res) => {
        setBoardsList(res.data);
      })
      .catch(() => {
        //TODO отобразить ошибку
      });
  }, []);

  return (
    <aside className={st.grid}>
      <div className={st.sidebar}>
        <div className={st.board}>
          {boardsList.map((board) => (
            <div className={st.wrap} key={board.id}>
              <Link
                className={st.board__link}
                to={"/boards/" + board.id.toString()}
              >
                <SVG src={boardsImg} />
                <div className={st.title}>{board.title}</div>
              </Link>
              <div className={st.card}>
                <Link
                  className={st.card__wrap}
                  to={`/boards/${board.id.toString()}/stages`}
                >
                  <div className={st.card__container}>
                    <SVG src={settingsImg} className={st.card__img} />
                    Настроить
                  </div>
                </Link>
                <div className={st.remove}>Удалить доску</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </aside>
  );
};

export default Boards;

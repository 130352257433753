import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import {loginActionWithData} from 'library/common/actions/user';
import {getIsAuthenticated, getLoginFailed} from 'library/common/selectors/user';

import Auth from './Auth';

const mapStateToProps = (store: RootState) => ({
	isAuthenticated: getIsAuthenticated(store),
	loginFailed: getLoginFailed(store),
});

export default connect(mapStateToProps,{
		loginActionWithData
})(Auth);

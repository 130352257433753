/* eslint-disable no-unused-vars */
import React from "react";
import Modal from "react-modal";
import { deleteCard } from "library/services/boardsApi";

import st from "./index.module.scss";

interface IRemove {
  open: boolean;
  closeModal: () => void;
  card: { id: number };
  showMsg: (data: any) => void;
  cardInfoAction: (data: any) => void;
  openCardModalAction: () => void;
}

const Remove: React.FC<IRemove> = ({
  open,
  closeModal,
  card,
  showMsg,
  cardInfoAction,
  openCardModalAction,
}: IRemove) => {
  const message = { errorColor: false, msg: "Карточка в корзине", show: true };
  const messageError = {
    errorColor: true,
    msg: "Не удалось переместить",
    show: true,
  };
  const newCard = { id: 0, board_id: 0 };

  function removeCard() {
    deleteCard(card.id)
      .then(() => {
        showMsg(message);
        cardInfoAction(newCard);
        openCardModalAction();
        closeModal();
      })
      .catch(() => {
        showMsg(messageError);
      });
  }

  return (
    <Modal
      isOpen={open}
      className={st.modal}
      overlayClassName={st.overlay}
      appElement={document.getElementById("root")}
      onRequestClose={() => closeModal()}
    >
      <div className={st.grid}>Переместить карточку в корзину?</div>
      <div className={st.wrap}>
        <div className={st.btn} onClick={() => removeCard()}>
          Да
        </div>
        <div className={st.btn} onClick={() => closeModal()}>
          Нет
        </div>
      </div>
    </Modal>
  );
};

export default Remove;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React from 'react';

import styles from './index.module.scss';

interface IField {
	value: string;
	type: string;
	onChange: (e: any, type: string, isPassword: any) => void;
	clickIcon?: (type: string, isPassword: any) => void;
	icon?: any;
	showPassword?: any;
	autoComplete: string;
	placeholder: string;
}

const Field: React.FC<IField> = ({value, type, onChange, icon, clickIcon, showPassword, autoComplete, placeholder}: IField) => (
	<div className={styles.field}>
		<input
			className={`${styles.field__input} ${showPassword ? styles.field__input_password : ''}`}
			type={type}
			value={value}
			onChange={e => onChange(e, type, showPassword)}
			autoComplete={autoComplete}
			placeholder={placeholder}
		/>
		{value &&
		<img
			onClick={() => clickIcon!(type, showPassword)}
			className={styles.field__icon}
			src={icon}
			alt=''
		/>
		}
	</div>
);

export default Field;

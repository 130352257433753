import { connect } from 'react-redux';

import {RootState} from 'core/store/configureStore';

import { getUserId } from 'library/common/selectors/user';
import {showMsg} from 'library/common/actions/shellActions';

import Personal from './Personal';

const mapStateToProps = (store: RootState) => ({
    id: getUserId(store),
});


export default connect(mapStateToProps, {showMsg})(Personal);
/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import {
  getCardTZ,
  putCardTZ,
  postDeliveryPaks,
  getSticker,
} from "library/services/boardsApi";
//import QrReader from 'react-qr-reader'

import st from "./index.module.scss";

import packsImg from "resources/img/paks.svg";
import colorImg from "resources/img/test/color.png";
import bigDone from "resources/img/bigDone.svg";

interface IQrScanner {
  qrModal: boolean;
  closeModal: () => void;
  showMsg: (data: any) => void;
  shipped: any[];
  card: any;
  id: number;
  updateShipped: Function;
}

const QrScanner: React.FC<IQrScanner> = ({
  qrModal,
  closeModal,
  showMsg,
  card,
  id,
  shipped,
  updateShipped,
}: IQrScanner) => {
  const [packs, setPacks] = useState<any[]>([]);
  const [order, setOrder] = useState<any[]>([]);
  const [briks, setBriks] = useState<any[]>([]);
  const [glue, setGlue] = useState<any>({});
  const [glueStatus, setGlueStatus] = useState<boolean>(false);
  const [orderWrap, setOrderWrap] = useState<any>({});

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };

  useEffect(() => {
    getOrderInfo();
  }, []);

  function getOrderInfo() {
    getCardTZ(id)
      .then((res) => {
        setOrder(
          res.data[0].items.find((item) => item.type !== "client").text.object
            .item
        );
        setGlue(
          res.data[0].items.find((item) => item.type !== "client").text.metadata
            .glue_packs
        );
        setGlueStatus(
          res.data[0].items.find((item) => item.type !== "client").text.object
            .glue_packs_shipped
        );
        setOrderWrap(res.data[0].items.find((item) => item.type !== "client")); // храним только для отправки галочки на упаковках клея, и потому что надо отправлять весь объект при изменении
        getMyPacks();
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function getMyPacks() {
    getSticker(id)
      .then((res) => {
        setPacks(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  useEffect(() => {
    getPaksInfo();
  }, [packs]);

  function getPaksInfo() {
    let newOrder = order.slice(); // состав заказа
    let newInfo = packs.slice(); // созданные коробки
    let deliveryPacks = [] as any;
    for (let i = 0; i < newOrder.length; i++) {
      // debugger
      let box = newInfo.filter(
        (item) => item.product_code == newOrder[i].product.id
      );
      let title = newOrder[i].product.title;
      deliveryPacks.push({ box, title });
    }
    setBriks(deliveryPacks);
  }

  function doneDelivery(brick: any) {
    postDeliveryPaks(brick)
      .then((res) => {
        updateShipped(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function doneGlue() {
    let newOrder = orderWrap;
    newOrder.text.object.glue_packs_shipped = !newOrder.glue_packs_shipped;
    putCardTZ(newOrder.id, newOrder)
      .then(() => {
        setGlueStatus(!setGlueStatus);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  return (
    <Modal
      isOpen={qrModal}
      className={st.modal}
      overlayClassName={st.overlay}
      appElement={document.getElementById("root")}
      onRequestClose={closeModal}
    >
      <div className={st.head}>
        <div className={st.head__order}>{card.title}</div>
        <div className={st.head__title}>Отгрузка</div>
      </div>
      <div className={st.body}>
        {briks.map((brick) => (
          <div key={brick.id} className={st.box}>
            {brick.box.map((item) => (
              <div className={st.quantity} key={item.id}>
                <div className={st.quantity__packs}>
                  <div className={st.quantity__box}>
                    <SVG src={packsImg} />
                  </div>
                  <div className={st.product}>
                    <div className={st.product__name}> {brick.title}</div>
                    <img src={colorImg} className={st.product__img} alt="" />
                  </div>
                  <div className={st.quantity__title}></div>
                </div>
                {shipped.findIndex((s) => s.serial === item.serial) !== -1 ? (
                  <div className={st.quantity__packs}>
                    <div className={st.quantity__number}>готово</div>
                    <div className={st.btn + " " + st.btn_active}>
                      <SVG src={bigDone} />
                    </div>
                  </div>
                ) : (
                  <div
                    className={st.quantity__packs}
                    onClick={() => doneDelivery(item)}
                  >
                    <div className={st.quantity__number}>отгрузить</div>
                    <div className={st.btn}>
                      <SVG src={bigDone} />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
        {glue && (
          <div className={st.quantity}>
            <div className={st.quantity__packs}>
              <div className={st.quantity__box}>
                <SVG src={packsImg} />
              </div>
              <div className={st.product}>
                <div className={st.product__name}>Клей</div>
              </div>
            </div>
            <div className={st.quantity__packs} onClick={() => doneGlue()}>
              <div className={st.quantity__number}>{glue.quantity} уп</div>
              <div className={st.btn + " " + (glueStatus ? st.btn_active : "")}>
                <SVG src={bigDone} />
              </div>
            </div>
          </div>
        )}
        {/* <div className={st.qr}>
          <QrReader 
          style={{ position: 'absolute', width: '220px', height: '220px', top: '-45px' }}/>
        </div> */}
        {/* <div className={st.line}>
          <div className={st.name}>Ввести QR вручную</div>
          <input className={st.input} value="а34а3а" disabled />
        </div> */}
        {/* <div className={st.line}>
          <div className={st.scanned}>Просканировано 3 из 12</div>
        </div> */}
        {/* <div className={st.wrapper}>
          <div className={st.line}>
            <div className={st.material}>Тычок 410123123213213</div>
            <div className={st.code}>232323WFMR-20-0202-90-71-ma</div>
          </div>
        </div> */}
      </div>
    </Modal>
  );
};

export default QrScanner;

export const getAvailableModulesSelector = (state: any) => state.shell.availableModules;

export const modalCard = (state: any) => state.order.modalCard;

export const card = (state: any) => state.order.card;

// export const cardId = (state: any) => state.order.card.id;

// export const boardId = (state: any) =>  state.order.card.board_id;

export const availableModules = (state: any) => state.order.availableModules;

export const orderCards = (state: any) => state.order.orderCards;
/* eslint-disable no-unused-vars */
import React from 'react';
import Modal from 'react-modal';
import { purgeCard } from 'library/services/boardsApi';

import st from './index.module.scss';

interface IDelete {
    open: boolean,
    closeModal: () => void,
    card: {id: number},
    showMsg: (data: any) => void,
    cardInfoAction: (data: any) => void,
    openCardModalAction: () => void,
}

const Delete: React.FC<IDelete> = ({open, closeModal, card, showMsg, cardInfoAction, openCardModalAction}: IDelete) => {

    const message = {errorColor: false, msg: 'Карточка удалена', show: true};
    const messageError = {errorColor: true, msg: 'Не удалось удалить карточку', show: true};
    const newCard = {id:0, board_id: 0}

    function removeCard() {
        purgeCard(card.id).then(() => {
            showMsg(message)
            cardInfoAction(newCard)
            openCardModalAction()
            closeModal()
        }).catch(error => {
            console.log(error)
            showMsg(messageError)
        })
    }

    return (
        <Modal isOpen={open}
        className={st.modal}
         overlayClassName={st.overlay}
         appElement={document.getElementById('root')}
         onRequestClose={() => closeModal()}
         >  
        <div className={st.grid}>Удалить карточку безвозвратно?</div>
        <div className={st.wrap}>
            <div className={st.btn} onClick={() => removeCard()}>Да</div>
            <div className={st.btn} onClick={() => closeModal()}>Нет</div>
        </div>
        </Modal>
    )
}

export default Delete;
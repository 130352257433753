/* eslint-disable no-debugger */
/* eslint-disable no-undef */
import {call, put, takeLatest} from 'redux-saga/effects';

import localStorage from 'library/utilities/localStorage';
import {loginActionSuccess, loginAction, loginActionError} from 'library/common/actions/user';
import {showMsg} from 'library/common/actions/shellActions';
import {UserTypes} from 'library/common/types/userTypes';
import {setAuthToken} from 'library/utilities/token';
import {requestHandShake, requestLogin} from 'library/services/userApi';

export function* loginUser(userData: any) {
	try {
		const handshakeResponse = yield call(requestHandShake);
		localStorage.setItem('access_token', handshakeResponse.headers.authorization);
		localStorage.setItem('email', userData.payload.email);
		setAuthToken(handshakeResponse.headers.authorization);
		const authResponse = yield call(requestLogin, userData.payload);
		yield put(loginActionSuccess(authResponse.data));
		yield put(loginAction());
	} catch (error) {
		yield put(showMsg({errorColor: true, msg: 'Что-то пошло не так', show: true}));
		yield put(loginActionError());
	}
}

export default function* watchLogin() {
	yield takeLatest(UserTypes.LOGIN_ACTION_WITH_DATA, loginUser);
}
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {getCardTZ, putCardTZ} from 'library/services/boardsApi';
import Input from 'library/common/components/Input';

import st from './index.module.scss';
interface IPrice {
    price?: any[];
    id: number,
    showMsg: (data: any) => void;
}
const Price: React.FC<IPrice> = ({id, showMsg}:IPrice) => {

  const message = {errorColor: true, msg: 'Что-то пошло не так', show: true}
  const [info, setInfo] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(true);
  const [orderTime, setOrderTime] = useState<string>('');
  const [initialInfo, setInitialInfo] = useState<any>({});

  useEffect(() => {
    getOrderInfo();
    },
  []);

  function getOrderInfo(){
    getCardTZ(id)
    .then((res) => {
      setInfo(res.data[0].items.find(item => item.type === 'client').text);
      setOrderTime(res.data[0].iso_date);
      setInitialInfo(res.data)
    })
    .catch(() => {
      showMsg(message)
    })
  }

  const changeInput = (event: React.ChangeEvent<HTMLInputElement>, field: any) => {
    let newInfo = {...info};
    newInfo[field] = event.target.value;
    setInfo(newInfo);
  }

  function setToServer() {
    let allData = initialInfo.slice();
    let currentIndex = initialInfo[0].items.findIndex(item => item.type === 'client');
    allData[0].items[currentIndex].text = info;
    putCardTZ(allData[0].items[currentIndex].id, allData[0].items[currentIndex])
    .then(() => {
        setDisabled(true)
    })
    .catch(() => {
        showMsg(message);
    })
  } 

  function handleForm(info) {
    if(info === 'ip'){
      return "ИП"
    } else if ( info === 'ooo') {
      return "OOO"
    } else if (info === 'fiz') {
      return "Физ. лицо"
    } else return ''
  }
  
    return(
        <div className={st.grid}>
          <div className={st.title}>Оплата</div>
          <div className={st.priсe}>Сумма заказа: {info.payment_amount/100} р</div>
          <div className={st.wrap}>
          <div className={st.line}>
            <div className={st.name}>Плат. поручение</div>
            <Input 
                                width={'180'}
                                height={'36'}
                                bcg={'f5f5f5'}
                                value={info.payment_order}
                                disabled={disabled}
                                onChange={(e) => changeInput(e, 'payment_order')}
                                />
            <div className={st.date}>Дата</div>
            <div className={st.input}>{orderTime}</div>
          </div>
          <div className={st.line}>
            <div className={st.name}>Способ оплаты</div>
            <Input 
                                width={'400'}
                                height={'36'}
                                bcg={'fdfbfb'}
                                value={info.payment_type}
                                disabled={true}
                                onChange={(e) => changeInput(e, 'payment_type')}
                                />
          </div>
         
          <div className={st.line}>
            <div className={st.name}>Сумма платежа, pуб</div>
            <Input 
                                width={'400'}
                                height={'36'}
                                bcg={'fdfbfb'}
                                value={info.payment_amount / 100}
                                disabled={true}
                                onChange={(e) => changeInput(e, 'payment_amount')}
                                />
          </div>
          <div className={st.line}>
            <div className={st.name}></div>
            <Input 
                                width={'90'}
                                height={'36'}
                                bcg={'fdfbfb'}
                                value={handleForm(info.client_form)}
                                disabled={true}
                                onChange={(e) => changeInput(e, 'client_form')}
                                />
            <div className={st.input__wrap}>
              <Input 
                                width={'292'}
                                height={'36'}
                                bcg={'fdfbfb'}
                                value={info.client_full_name}
                                disabled={true}
                                onChange={(e) => changeInput(e, 'client_full_name')}
                                />
            </div>
          </div>
          <div className={st.line}>
            <div className={st.name}>ИНН</div>
            <Input 
                                width={'400'}
                                height={'36'}
                                bcg={'fdfbfb'}
                                value={info.client_inn}
                                disabled={true}
                                onChange={(e) => changeInput(e, 'client_inn')}
                                />
          </div>
          <div className={st.line}>
            <div className={st.name}>Р/счет</div>
            <Input 
                                width={'400'}
                                height={'36'}
                                bcg={'fdfbfb'}
                                value={info.client_payment_number}
                                disabled={true}
                                onChange={(e) => changeInput(e, 'client_payment_number')}
                                />
          </div>
          <div className={st.line}>
          <div className={st.name}>БИК</div>
          <Input 
                                width={'400'}
                                height={'36'}
                                bcg={'fdfbfb'}
                                value={info.client_bik}
                                disabled={true}
                                onChange={(e) => changeInput(e, 'client_bik')}
                                />
          </div>
          <div className={st.line}>
          <div className={st.name}>Кор. счет</div>
          <Input 
                                width={'400'}
                                height={'36'}
                                bcg={'fdfbfb'}
                                value={info.client_correspondent_number}
                                disabled={true}
                                onChange={(e) => changeInput(e, 'client_correspondent_number')}
                                />
          </div>
          <div className={st.line}>
            <div className={st.name}>ОГРН</div>
            <Input 
                                  width={'400'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.client_ogrn}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'client_ogrn')}
                                  />
          </div>
          <div className={st.line}>
            <div className={st.name}>ОКПО</div>
            <Input 
                                  width={'400'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.client_okpo}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'client_okpo')}
                                  />
          </div>
          <div className={st.line}>
            <div className={st.name}>Телефон</div>
            <Input 
                                  width={'400'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.client_phone}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'client_phone')}
                                  />
          </div>
          <div className={st.line}>
            <div className={st.name}>E-mail</div>
            <Input 
                                  width={'400'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.client_email}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'client_email')}
                                  />
          </div>
          <div className={st.line}>
            <div className={st.name}>Почтовый адрес</div>
            <Input 
                                  width={'400'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.client_postal_address}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'client_postal_address')}
                                  />
          </div>
          <div className={st.line}>
            <div className={st.name}>Фактический адрес</div>
            <Input 
                                  width={'400'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.client_physical_address}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'client_physical_address')}
                                  />
          </div>
          <div className={st.line}>
            <div className={st.name}>Адрес</div>
            <Input 
                                  width={'400'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.client_organization_address}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'client_organization_address')}
                                  />
          </div>
          <div className={st.line}>
            <div className={st.name}>Адрес объекта</div>
            <Input 
                                  width={'400'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.order_city}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'order_city')}
                                  />
          </div>
          <div className={st.line}>
            <div className={st.name}></div>
              <Input 
                                  width={'300'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.order_street}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'order_street')}
                                  />
              <div className={st.input__wrap}>
                <Input 
                                  width={'90'}
                                  height={'36'}
                                  bcg={'fdfbfb'}
                                  value={info.order_house}
                                  disabled={true}
                                  onChange={(e) => changeInput(e, 'order_house')}
                                  />
                </div>
          </div>
        </div>
        <div className={st.btn}>
          {disabled ? 
            <div className={st.btn__title} onClick={() => setDisabled(!disabled)}>Редактировать</div>
            :
            <div className={st.btn__title} onClick={() => setToServer()}>Сохранить</div>
          }
        </div>
    </div>
    )
}

export default Price;
/* eslint-disable no-unused-vars */
import React from 'react';
import Modal from 'react-modal';

import styles from './index.module.scss';

interface IColors {
    open: boolean,
    closeModal: () => void,
    changeCardColorAction: (string) => void
}

const Colors: React.FC<IColors> = ({open, closeModal, changeCardColorAction}:IColors) => {

 return (
    <Modal isOpen={open}
           className={styles.modal}
            overlayClassName={styles.overlay}
            appElement={document.getElementById('root')}
            onRequestClose={() => closeModal()}
            >   
            <div className={styles.grid}>
                <div className={styles.color} onClick={() => changeCardColorAction('899fcc')}/>
                <div className={styles.color} onClick={() => changeCardColorAction('77b8d9')}/>
                <div className={styles.color} onClick={() => changeCardColorAction('75cc95')}/>
                <div className={styles.color} onClick={() => changeCardColorAction('f5c43f')}/>
                <div className={styles.color} onClick={() => changeCardColorAction('fd8f63')}/>
                <div className={styles.color} onClick={() => changeCardColorAction('f24841')}/>
            </div>
    </Modal>
 )
}

export default Colors;
/* eslint-disable no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";

import st from "./index.module.scss";

import startImg from "resources/img/stepStart.svg";
import finishImg from "resources/img/stepFinish.svg";
import leftImg from "resources/img/blueArrowLeft.svg";
import rightImg from "resources/img/blueArrowRight.svg";

interface IStep {
  item: any;
  index: Number;
  edit: Number;
  setEdit: Function;
  changeStep: Function;
  handleColumns: Function;
  changeType: Function;
  setModal: Function;
  onDrop: Function;
}

const Step: React.FC<IStep> = ({
  item,
  index,
  edit,
  setEdit,
  changeStep,
  handleColumns,
  changeType,
  setModal,
  onDrop,
}: IStep) => {
  const [{ isDragging }, drag] = useDrag({
    //Объект что перетягиваем (можно прописывать любые свойства)
    item: { id: item.id, type: "card", position: item.position },
    end: (drag, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      // dropResult - что перетягиваем (объект из useDrag)
      // item - что перетягиваем (объект из useDrop)
      onDrop(drag, dropResult.position);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "card",
    //Объект куда перетягиваем (можно прописывать любые свойства)
    drop: () => ({ position: item.position }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div className={st.card} key={item.id} ref={drop}>
      <div
        className={st.overlay + " " + (edit === index ? st.overlay_active : "")}
        onClick={() => setEdit(-1)}
      />
      <div
        className={
          st.step +
          " " +
          (edit === index ? st.step_active : "") +
          " " +
          (isDragging ? st.step_dragged : "")
        }
        ref={drag}
        onClick={() => setEdit(index)}
      >
        <div className={st.head}>
          <div className={st.head__empty} />
          <input
            className={st.name}
            value={item.title}
            onBlur={() => changeStep(index)}
            onChange={(e) => handleColumns(e, index, "title")}
            disabled={index !== edit}
          />
          <div className={st.icon}>
            <SVG
              className={
                st.img + " " + (item.type === "first" ? st.img_active : "")
              }
              src={startImg}
            />
            <SVG
              className={
                st.img + " " + (item.type === "last" ? st.img_active : "")
              }
              src={finishImg}
            />
          </div>
        </div>
        <div className={st.container}>
          <div
            className={
              st.positions + " " + (index === edit ? st.positions_active : "")
            }
          >
            <div className={st.positions__wrap}>
              <SVG
                src={startImg}
                className={st.positions__img}
                onClick={() => changeType("first", index)}
              />
              <div className={st.positions__title}>старт</div>
            </div>
            <div className={st.positions__wrap}>
              <SVG
                src={finishImg}
                className={st.positions__img}
                onClick={() => changeType("last", index)}
              />
              <div className={st.positions__title}>финиш</div>
            </div>
          </div>
          <div className={st.container__wrap}>
            <SVG src={leftImg} className={st.left} />
            <SVG src={rightImg} className={st.left} />
          </div>
          <div
            className={
              st.remove + " " + (index === edit ? st.remove_active : "")
            }
            onClick={() => setModal(true)}
          >
            Удалить этап
          </div>
        </div>
      </div>
      {isOver && <div className={st.stepPlaceholder}></div>}
    </div>
  );
};

export default Step;

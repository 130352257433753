/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  getMember,
  changeMember,
  deleteMember,
  addAvatar,
} from "library/services/boardsApi";
import { history } from "core/store/configureStore";
import SVG from "react-inlinesvg";
import Input from "library/common/components/Input";
import PhotoLoader from "library/common/components/PhotoLoader";

import st from "./index.module.scss";

import memberImg from "resources/img/member.svg";
import userImg from "resources/img/members.svg";
import keyImg from "resources/img/key.svg";
import plus from "resources/img/add.svg";
import reportImg from "resources/img/report.svg";
import endImg from "resources/img/end.svg";
import basket from "resources/img/basket.svg";

interface IMember {
  showMsg: (data: any) => void;
  match: any;
}

const Member: React.FC<IMember> = ({ showMsg, match }: IMember) => {
  const [member, setMember] = useState<any>({
    dataitems: {
      phone: [],
      email: [],
      address: [],
      web: [],
    },
  });
  const [keyTab, setKeyTab] = useState<boolean>(false);
  const [modules, setModules] = useState<any>({
    global_modules: {
      archive: false,
      basket: false,
      clients: false,
      reports: false,
    },
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [photo, setPhoto] = useState<any>({});

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };
  const messageSuccess = { errorColor: false, msg: "Сохранено", show: true };
  const messageRemove = {
    errorColor: false,
    msg: "Участник удален",
    show: true,
  };

  useEffect(() => {
    getUserInfo();
  }, [history.location.pathname]);

  function getUserInfo() {
    getMember(match.params.id)
      .then((res) => {
        setMember(res.data);
        if (res.data.dataitems.phone.length === 0) {
          // для нарядной верстки если нет контактов
          let newInfo = res.data;
          let phone = {
            number: "",
            //'extra_code': ''
          };
          newInfo.dataitems.phone.push(phone);
          setMember(newInfo);
        }
        if (res.data.dataitems.email.length === 0) {
          let newInfo = res.data;
          let email = {
            data: "",
          };
          newInfo.dataitems.email.push(email);
        }
        let modules = { global_modules: { ...res.data.global_modules } };
        setModules(modules);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  const changeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let newInfo = { ...member };
    newInfo[field] = event.target.value;
    setMember(newInfo);
  };

  const changeContacts = (
    event: React.ChangeEvent<HTMLInputElement>,
    contact: string,
    field: string,
    index: number
  ) => {
    let newInfo = { ...member };
    newInfo.dataitems[contact][index][field] = event.target.value;
    setMember(newInfo);
  };

  const changeAccessModules = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let newModules = { ...modules };
    newModules.global_modules[field] = !newModules.global_modules[field];
    setModules(newModules);
    console.log(modules);
  };

  function addPhone() {
    let newInfo = { ...member };
    let phone = {
      number: "",
      extra_code: "",
    };
    newInfo.dataitems.phone.push(phone);
    setMember(newInfo);
  }

  function addPhoto() {
    let data = new FormData();
    data.append("logo", photo);
    addAvatar(match.params.id, data)
      .then(() => {})
      .catch(() => {
        showMsg(message);
      });
  }

  function saveUserInfo() {
    let newMember = { ...member };
    delete newMember["global_modules"];
    console.log("member", member);
    changeMember(match.params.id, newMember)
      .then((res) => {
        if (res.status === 200) {
          showMsg(messageSuccess);
          setDisabled(!disabled);
          changeMember(res.data.id, modules);
        }
      })
      .catch(() => {
        showMsg(message);
      })
      .finally(() => {
        if (photo.name) {
          addPhoto();
        }
      });
  }

  function addMail() {
    let newInfo = { ...member };
    let email = {
      data: "",
    };
    newInfo.dataitems.email.push(email);
    setMember(newInfo);
  }

  function removeUser() {
    deleteMember(match.params.id)
      .then(() => {
        showMsg(messageRemove);
        gotoMembers();
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function gotoMembers() {
    history.push("/members/");
  }

  return (
    <Modal
      isOpen={true}
      className={st.modal}
      overlayClassName={st.overlay}
      onRequestClose={() => gotoMembers()}
      appElement={document.getElementById("root")}
    >
      <div className={st.head}>
        <div className={st.head__name}>{member.full_name}</div>
        <div
          className={st.userTab + " " + (!keyTab ? st.userTab_active : "")}
          onClick={() => setKeyTab(false)}
        >
          <img src={memberImg} alt="" />
        </div>
        <div
          className={st.keyTab + " " + (keyTab ? st.keyTab_active : "")}
          onClick={() => setKeyTab(true)}
        >
          <img src={keyImg} alt="" />
        </div>
      </div>
      <div className={st.wrap}>
        <div className={st.foto}>
          <PhotoLoader
            photo={member.logo_url}
            width={185}
            height={185}
            edit={disabled}
            setPhoto={setPhoto}
          />
        </div>
        {!keyTab ? (
          <div className={st.container}>
            <div className={st.line}>
              <div className={st.line__name}>ФИО</div>
              <div className={st.line__wrap}>
                <Input
                  width={"159"}
                  value={member.last_name}
                  disabled={disabled}
                  onChange={(e) => changeInput(e, "last_name")}
                />
                <Input
                  width={"159"}
                  value={member.first_name}
                  disabled={disabled}
                  onChange={(e) => changeInput(e, "first_name")}
                />
                <Input
                  width={"159"}
                  value={member.middle_name}
                  disabled={disabled}
                  onChange={(e) => changeInput(e, "middle_name")}
                />
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Телефон</div>
              <div className={st.line__container}>
                {member.dataitems.phone.map((item, index) => (
                  <div className={st.line__wrapper} key={index}>
                    <Input
                      width={"315"}
                      value={item.number}
                      disabled={disabled}
                      onChange={(e) =>
                        changeContacts(e, "phone", "number", index)
                      }
                    />
                    <div className={st.tip}>Доб.</div>
                    <Input
                      width={"83"}
                      value={item.extra_code}
                      disabled={disabled}
                      onChange={(e) =>
                        changeContacts(e, "phone", "extra_code", index)
                      }
                    />
                  </div>
                ))}
              </div>
              <div
                className={st.btn + " " + (!disabled && st.btn_active)}
                onClick={addPhone}
              >
                <SVG src={plus} />
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Email</div>
              <div className={st.line__container}>
                {member.dataitems.email.map((item, index) => (
                  <div className={st.line__wrapper} key={index}>
                    <Input
                      width={"458"}
                      value={item.data}
                      disabled={disabled}
                      onChange={(e) =>
                        changeContacts(e, "email", "data", index)
                      }
                    />
                  </div>
                ))}
              </div>
              <div
                className={st.btn + " " + (!disabled && st.btn_active)}
                onClick={addMail}
              >
                <SVG src={plus} />
              </div>
            </div>
            <div className={st.line}>
              <div className={st.line__name}>Должность</div>
              <Input
                width={"458"}
                value={member.position}
                disabled={disabled}
                onChange={(e) => changeInput(e, "position")}
              />
            </div>
          </div>
        ) : (
          <div className={st.container}>
            <div className={st.group}>
              <div className={st.group__name}>Доступ к пунктам меню</div>
              <div className={st.group__box}>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.global_modules.clients}
                    className={st.check}
                    disabled={disabled}
                    onChange={(e) => changeAccessModules(e, "clients")}
                    id="client"
                  />
                  <label className={st.module} htmlFor="client">
                    <SVG src={userImg} className={st.userImg} />
                    <div className={st.module__name}>Клиенты</div>
                  </label>
                </div>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.global_modules.reports}
                    className={st.check}
                    disabled={disabled}
                    onChange={(e) => changeAccessModules(e, "reports")}
                    id="report"
                  />
                  <label className={st.module} htmlFor="report">
                    <SVG src={reportImg} className={st.userImg} />
                    <div className={st.module__name}>Отчеты</div>
                  </label>
                </div>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.global_modules.archive}
                    className={st.check}
                    disabled={disabled}
                    onChange={(e) => changeAccessModules(e, "archive")}
                    id="end"
                  />
                  <label className={st.module} htmlFor="end">
                    <SVG src={endImg} className={st.userImg} />
                    <div className={st.module__name}>Архив</div>
                  </label>
                </div>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.global_modules.basket}
                    className={st.check}
                    disabled={disabled}
                    onChange={(e) => changeAccessModules(e, "basket")}
                    id="basket"
                  />
                  <label className={st.module} htmlFor="basket">
                    <SVG src={basket} className={st.userImg} />
                    <div className={st.module__name}>Корзина</div>
                  </label>
                </div>
                <div className={st.group__line}>
                  <input
                    type="checkbox"
                    checked={modules.members}
                    className={st.check}
                    disabled={disabled}
                    onChange={(e) => changeAccessModules(e, "members")}
                    id="members"
                  />
                  <label className={st.module} htmlFor="members">
                    <SVG src={basket} className={st.userImg} />
                    <div className={st.module__name}>Сотрудники</div>
                  </label>
                </div>
              </div>
            </div>
            <div className={st.group}>
              <div className={st.group__loginName}>Логин</div>
              <div className={st.group__login}>{member.username}</div>
              <div className={st.accessBtn}>Ограничить доступ</div>
            </div>
          </div>
        )}
      </div>
      <div className={st.foot}>
        {!disabled && (
          <SVG
            src={basket}
            className={st.remove}
            onClick={() => removeUser()}
          />
        )}
        {disabled ? (
          <div className={st.foot__btn} onClick={() => setDisabled(!disabled)}>
            Изменить
          </div>
        ) : (
          <div className={st.foot__btn} onClick={() => saveUserInfo()}>
            Сохранить
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Member;

import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import { showMsg } from 'library/common/actions/shellActions';
import { openCardBasketAction, getAvailableModulesAction } from 'library/common/actions/orderActions';
import { card } from 'library/common/selectors/orderSelectors';


import Basket from './Basket';

const mapStateToProps = (store: RootState) => ({
    card: card(store)
});

export default connect(mapStateToProps, {
    showMsg,
    openCardBasketAction,
    getAvailableModulesAction
})(Basket);
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-debugger */
import React from 'react';
import SVG from 'react-inlinesvg';
import {NavLink, Route, Switch} from 'react-router-dom';
import Layers from './Frames/Layers';
import Steps from './Frames/Steps';
import Transfer from './Frames/Transfer';
import Human from './Frames/Human';
import Floors from './Frames/Floors';
import {history} from 'core/store/configureStore';

import st from './index.module.scss';

import layersImg from 'resources/img/layers.svg';
import humanImg from 'resources/img/human.svg';
import stagesImg from 'resources/img/stages.svg';
import stageImg from 'resources/img/stage.svg';
import arrowImg from 'resources/img/arrowRight.svg';

interface ISettingsBoard {
    match: any
}

const SettingsBoard: React.FC<ISettingsBoard> = ({match}: ISettingsBoard) => {

    const message = {errorColor: true, msg: 'Что-то пошло не так', show: true};

    return(
        <div className={st.grid}>
            <aside className={st.sidebar}>
                <NavLink className={st.stage + ' ' +(history.location.pathname == `/boards/${match.params.id}/stages` ? st.stage_active : '')} to={`/boards/${match.params.id}/stages`}>
                    <div className={st.stage__frame}>
                        <SVG src={stagesImg} className={st.stage__img} />
                    </div>
                    <div className={st.stage__title}>Этапы</div>
                </NavLink>
                <NavLink className={st.stage} activeClassName={st.stage_active} to={`/boards/${match.params.id}/stages/transfer`}>
                    <div className={st.stage__frame}>
                        <SVG src={arrowImg} className={st.stage__img} />
                    </div>
                    <div className={st.stage__title}>Переход карточки</div>
                </NavLink>
                <NavLink className={st.stage} activeClassName={st.stage_active} to={`/boards/${match.params.id}/stages/floor`}>
                    <div className={st.stage__frame}>
                        <SVG src={stageImg} className={st.stage__img} />
                    </div>
                    <div className={st.stage__title}>Переход на доски</div>
                </NavLink>
                <NavLink className={st.stage} activeClassName={st.stage_active} to={`/boards/${match.params.id}/stages/human`}>
                    <div className={st.stage__frame}>
                        <SVG src={humanImg} className={st.stage__img} />
                    </div>
                    <div className={st.stage__title}>Назначить сотрудников</div>
                </NavLink>
                <NavLink className={st.stage} activeClassName={st.stage_active} to={`/boards/${match.params.id}/stages/layers`}>
                    <div className={st.stage__frame}>
                        <SVG src={layersImg} className={st.stage__img} />
                    </div>
                    <div className={st.stage__title}>Назначить модули</div>
                </NavLink>
            </aside>
            <div></div>
            <Switch>
                <Route exact path='/boards/:id/stages' component={Steps}/>
                <Route path='/boards/:id/stages/transfer' component={Transfer}/>
                <Route path='/boards/:id/stages/floor' component={Floors}/>
                <Route path='/boards/:id/stages/human' component={Human }/>
                <Route path='/boards/:id/stages/layers' component={Layers }/>
            </Switch>
        </div>
    )
}

export default SettingsBoard;
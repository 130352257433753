/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  getTransitions,
  deleteTransition,
  postTransition,
} from "library/services/boardsApi";

import st from "./index.module.scss";

import startImg from "resources/img/stepStart.svg";
import finishImg from "resources/img/stepFinish.svg";
import arrowImg from "resources/img/blueArrow.svg";
import menu from "resources/img/menu.svg";
import lk from "resources/img/lk.svg";
import plus from "resources/img/add.svg";
import deleteImg from "resources/img/littleDlt.svg";

interface ITransfer {
  match: any;
  showMsg: (data: any) => void;
}

const Transfer: React.FC<ITransfer> = ({ match, showMsg }: ITransfer) => {
  const [trans, setTrans] = useState<any[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [card, setCard] = useState<any>({ title: "" });

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };
  const success = { errorColor: false, msg: "Переход добавлен", show: true };
  const successDelete = {
    errorColor: false,
    msg: "Переход удален",
    show: true,
  };

  useEffect(() => {
    getAllTransitions();
  }, []);

  function getAllTransitions() {
    getTransitions(match.params.id).then((res) => {
      setTrans(res.data);
    });
  }

  function startEdit(item) {
    setEdit(!edit);
    setCard(item);
  }

  function changeTransition(item) {
    let data = new FormData();
    data.append("from", card.id);
    data.append("to", item.id);
    if (card.transition_on_columns.find((column) => column.id === item.id)) {
      //debugger
      deleteTransition(data)
        .then(() => {
          let newCard = { ...card };
          let current = newCard.transition_on_columns.findIndex(
            (current) => current.id === item.id
          );
          newCard.transition_on_columns.splice(current, 1);
          let allCards = trans.slice();
          let currentCard = allCards.findIndex(
            (current) => current.id === newCard.id
          );
          allCards[currentCard] = newCard;
          setTrans(allCards);
          showMsg(successDelete);
        })
        .catch(() => {
          showMsg(message);
        });
    } else {
      postTransition(data)
        .then((res) => {
          let newCard = { ...card };
          newCard.transition_on_columns.push({
            id: item.id,
            title: item.title,
          });
          let allCards = trans.slice();
          let currentCard = allCards.findIndex(
            (item) => item.id === newCard.id
          );
          allCards[currentCard] = newCard;
          setTrans(allCards);
          showMsg(success);
        })
        .catch(() => {
          showMsg(message);
        });
    }
  }

  return (
    <div className={st.grid}>
      {edit ? (
        <div className={st.wrapper}>
          <div className={st.step} onClick={() => setEdit(true)}>
            <div className={st.head}>
              <div className={st.head__empty} />
              <div className={st.name}>{card.title}</div>
              <div className={st.head__empty} />
            </div>
            <div className={st.container}>
              <div className={st.card} />
            </div>
          </div>
          <SVG src={arrowImg} className={st.blueArrow} />
          <div className={st.diagram}>
            <div className={st.overlay} onClick={() => startEdit({})} />
            <div className={st.diagram__title}>
              Выберете этапы на которые будет переходить карточка
            </div>
            <div className={st.diagram__box}>
              <div className={st.diagram__menu}>
                <div className={st.diagram__left}>
                  <div className={st.diagram__img}>
                    <SVG src={menu} />
                  </div>
                  <div className={st.diagram__img}>
                    <SVG src={plus} />
                  </div>
                </div>
                <SVG src={lk} className={st.diagram__lk} />
              </div>
              <div className={st.diagram__container}>
                <div className={st.diagram__overlay}>
                  {trans.map((item, index) =>
                    card.id === item.id ? (
                      <div className={st.diagram__empty} key={item.id} />
                    ) : (
                      <div
                        className={st.diagram__stage}
                        key={item.id}
                        onClick={() => changeTransition(item)}
                      >
                        <div className={st.diagram__head}>
                          <div className={st.diagram__subtitle}>
                            {item.title}
                          </div>
                        </div>
                        <div className={st.diagram__wrap}>
                          <div
                            className={
                              st.diagram__btn +
                              " " +
                              (typeof card.transition_on_columns.find(
                                (selectCard) => selectCard.id === item.id
                              ) != "undefined"
                                ? st.diagram__btn_active
                                : "")
                            }
                          >
                            <SVG
                              src={deleteImg}
                              className={st.diagram__delete}
                            />
                          </div>
                          <div className={st.diagram__bigTitle}>
                            {item.title}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={st.wrap}>
          {trans.map((item) => (
            <div
              className={st.step}
              key={item.id}
              onClick={() => startEdit(item)}
            >
              <div className={st.head}>
                <div className={st.head__empty} />
                <div className={st.name}>{item.title}</div>
                <div className={st.icon}>
                  <SVG
                    className={
                      st.img +
                      " " +
                      (item.type === "first" ? st.img_active : "")
                    }
                    src={startImg}
                  />
                  <SVG
                    className={
                      st.img + " " + (item.type === "last" ? st.img_active : "")
                    }
                    src={finishImg}
                  />
                </div>
              </div>
              <div className={st.container}>
                {item.transition_on_columns.map((ways) => (
                  <div key={ways.id} className={st.ways}>
                    {ways.title}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Transfer;

/* eslint-disable no-debugger */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import {
  getMembersAccounts,
  getAllAccounts,
  postMembers,
  deleteMembers,
} from "library/services/boardsApi";

import SVG from "react-inlinesvg";

import st from "./index.module.scss";

import startImg from "resources/img/stepStart.svg";
import finishImg from "resources/img/stepFinish.svg";
import doneImg from "resources/img/doneImg.svg";

interface IHuman {
  match: any;
  showMsg: (data: any) => void;
}

const Human: React.FC<IHuman> = ({ match, showMsg }: IHuman) => {
  const [users, setUsers] = useState<any[]>([]);
  const [members, setMembers] = useState<any[]>([]);
  const [edit, setEdit] = useState<number>(-1);

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };
  const messageSuccess = {
    errorColor: false,
    msg: "Учасник добавлен",
    show: true,
  };
  const success = { errorColor: false, msg: "Учасник удален", show: true };

  useEffect(() => {
    getHumans();
    getAllUsers();
  }, []);

  function getHumans() {
    getMembersAccounts(match.params.id)
      .then((res) => {
        setMembers(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function getAllUsers() {
    getAllAccounts()
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function addMember(user, cardId, index) {
    const member = {
      accountId: user.id,
      columnId: cardId,
    };
    postMembers(member)
      .then(() => {
        showMsg(messageSuccess);
        let newMembers = members.slice();
        newMembers[index].accounts.push(user);
        setMembers(newMembers);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  function removeMember(id, cardId, index) {
    const member = {
      accountId: id,
      columnId: cardId,
    };
    deleteMembers(member)
      .then(() => {
        showMsg(success);
        let newMembers = members.slice();
        //debugger
        let user = newMembers[index].accounts.findIndex(
          (item) => item.id === id
        );
        newMembers[index].accounts.splice(user, 1);
        setMembers(newMembers);
      })
      .catch(() => {
        showMsg(message);
      });
  }

  return (
    <div className={st.grid}>
      <div className={st.wrap}>
        {members.map((card, index) => (
          <div className={st.card} key={card.id}>
            <div
              className={
                st.overlay + " " + (edit === index ? st.overlay_active : "")
              }
              onClick={() => setEdit(-1)}
            />
            <div
              className={st.step + " " + (edit === index ? st.step_active : "")}
              onClick={() => setEdit(index)}
            >
              <div className={st.head}>
                <div className={st.head__empty} />
                <div className={st.name}>{card.title}</div>
                <div className={st.icon}>
                  <SVG
                    className={
                      st.img +
                      " " +
                      (card.type === "first" ? st.img_active : "")
                    }
                    src={startImg}
                  />
                  <SVG
                    className={
                      st.img + " " + (card.type === "last" ? st.img_active : "")
                    }
                    src={finishImg}
                  />
                </div>
              </div>
              <div className={st.container}>
                {edit !== index
                  ? card.accounts.map(
                      (account) =>
                        !account.is_admin && (
                          <div
                            className={st.user + " " + st.user_active}
                            key={account.id}
                          >
                            <img className={st.avatar} src={account.logo_url} />
                            <div className={st.user__name}>
                              {account.short_name}
                            </div>
                          </div>
                        )
                    )
                  : users.map((user) =>
                      card.accounts.findIndex((ac) => ac.id === user.id) !==
                      -1 ? (
                        <div
                          className={st.user + " " + st.user_active}
                          key={user.id}
                          onClick={() => removeMember(user.id, card.id, index)}
                        >
                          <SVG src={doneImg} className={st.done} />
                          <div className={st.user__name}>{user.short_name}</div>
                        </div>
                      ) : (
                        <div
                          className={st.user}
                          key={user.id}
                          onClick={() => addMember(user, card.id, index)}
                        >
                          <img className={st.avatar} src={user.logo_url} />
                          <div className={st.user__name}>{user.short_name}</div>
                        </div>
                      )
                    )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Human;

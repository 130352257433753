/* eslint-disable no-undef */
import React, { useState } from "react";
import SVG from "react-inlinesvg";

import st from "./index.module.scss";

import arrowImg from "resources/img/arrow.svg";

interface IDropDown {
  options: any[];
  defaultValue: any;
  disabled: boolean;
  changeValue: Function;
}

const DropDown: React.FC<IDropDown> = ({
  options,
  defaultValue,
  disabled,
  changeValue,
}: IDropDown) => {
  //const [value, setValue] = useState<any>(defaultValue);
  const [open, setOpen] = useState<boolean>(false);
  //const [options, setOptions] = useState<any[]>(options);

  function changeDelivery(item) {
    changeValue(item);
    setOpen(false);
  }

  return (
    <div className={st.grid + " " + (!disabled ? st.grid_active : "")}>
      <div
        className={st.overlay + " " + (open ? st.overlay_active : "")}
        onClick={() => setOpen(false)}
      />
      <div
        className={st.value + " " + (!disabled ? st.value_active : "")}
        onClick={() => setOpen(!open)}
      >
        {defaultValue.title}
      </div>
      <SVG
        src={arrowImg}
        className={st.arrow + " " + (open ? st.arrow_active : "")}
      />
      <ul className={st.list + " " + (open ? st.list_active : "")}>
        {options.map((item) => (
          <div
            className={st.item}
            key={item.id}
            onClick={() => changeDelivery(item)}
          >
            {item.title}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default DropDown;

import { fetch } from "../utilities/fetch";

export const requestHandShake = () => fetch.post("/api/v1/auth/handshake/");

export const requestLogin = (data: any) =>
  fetch.post("/api/v1/auth/signin/", {
    username: data.email,
    password: data.password,
  });

export const getPersonalInfo = (id) => fetch.get(`/api/v1accounts/${id}/`);

export const addAvatar = (id, data) =>
  fetch.patch(`/api/v1/accounts/${id}/avatar/`, data);

export const getOut = () => fetch.post("/api/v1/auth/signout/", {});

export const postNewPass = (data) =>
  fetch.post("/api/v1/auth/change_password/", data);

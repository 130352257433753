import {connect} from 'react-redux';
import {RootState} from 'core/store/configureStore';

import {showMsg} from 'library/common/actions/shellActions';
import {card} from 'library/common/selectors/orderSelectors';

import QrScanner from './QrScanner'

const mapStateToProps = (store: RootState) => ({
    card: card(store),
});

export default connect(mapStateToProps,{
    showMsg
})(QrScanner);
import React from 'react';

import styles from './index.module.scss'

interface INoteText {
    key?: string;
    massege:string;
    createMassege: any;
    saveMassege: any;
    activeNoteObject: any;
}
const NoteText: React.FC<INoteText> = ({createMassege, massege, saveMassege, activeNoteObject}:INoteText) => {
    return <textarea className={styles.text} 
                     onChange={(e) => createMassege(e)} 
                     onBlur={() => saveMassege(massege)} 
                     value={activeNoteObject.text !== 'Новая заметка' ? activeNoteObject.text : ''}>
            </textarea>
}

export default NoteText;


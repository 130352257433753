/* eslint-disable no-unused-vars */

import React from "react";
import { useDispatch } from "react-redux";
import { showMsg } from "library/common/actions/shellActions";
import { getCardTZ, putCardTZ } from "library/services/boardsApi";

import ProductCard from "./frames/ProductCard";

import st from "./RelifeTz.module.scss";

type Props = {
  id: number;
};

const RelifeTz: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };

  const [initialTz, setInitialTz] = React.useState<any>([]);

  const getTzText = (text: string) => {
    let itemText = text;
    let idx = itemText.search("\nЗаказ:\nId:");
    itemText = itemText.slice(0, idx).replaceAll("\n", "</br>");
    return itemText;
  };

  React.useEffect(() => {
    getCardTZ(id)
      .then((res) => {
        setInitialTz(res.data);
      })
      .catch(() => {
        dispatch(showMsg(message));
      });
  }, []);

  return (
    <div className={st.grid}>
      <section
        className={st.text}
        dangerouslySetInnerHTML={{
          __html: initialTz.map((tz) =>
            tz.items.map((item) => getTzText(item.text))
          ),
        }}
      ></section>
      <section className={st.products}>
        Заказ:
        {initialTz.map((tz) =>
          tz.items.map((item) =>
            item.json.items.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))
          )
        )}
      </section>
    </div>
  );
};

export default RelifeTz;

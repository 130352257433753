import {connect} from 'react-redux';
import {RootState} from 'core/store/configureStore';

import ErrorComponent from './ErrorComponent';

import { message } from 'library/common/selectors/shellSelectors';
import { showMsg } from 'library/common/actions/shellActions';

const mapStateToProps = (store: RootState) => ({
	message: message(store)
});


export default connect(mapStateToProps, {showMsg})(ErrorComponent);
import React from 'react';

import styles from './index.module.scss'

interface INoteItem {
    item: any
    key: string;
    isActiveNote?: boolean;
    index: number;
    activNote: number;
    onCrossClick: any;
    crossActive: number;
    delNote: any;
    changeActiveNote: any;
    createTitle: any;
    activeNoteObject: any;
}

function setShortDate(date) {
    let options: any = {
        year: "2-digit",
        month: "numeric",
        day: "numeric"
    }
    return new Date(date).toLocaleDateString('ru-RU',options)
}

const NoteItem: React.FC<INoteItem> = ({/*activeNoteObject,*/item, activNote, index, onCrossClick, crossActive, delNote, changeActiveNote, createTitle}: INoteItem) => {
    let changeClass = index === activNote ? styles.noteList__item__active : '' 
    let crossAddClass = index === crossActive ? styles.noteList__wrapper__cross : ''
    
    return (
        <div className={styles.noteList__wrapper + ' ' + changeClass + ' ' + crossAddClass}>
            <div onClick={() => changeActiveNote(index)} className={styles.noteList__item + ' ' + changeClass}>
                <p>{createTitle(item.text)}</p>
                <p>{setShortDate(item.created)}</p> 
                <span onClick={() => onCrossClick(index)}>×</span>
            </div>
            <div onClick={() => delNote(index)} className={styles.noteList__delete}>Удалить</div>
        </div>
    )   
}

export default NoteItem;
/* eslint-disable no-undef */
import React from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";

import boards from "resources/img/boards.svg";
import members from "resources/img/members.svg";
import clients from "resources/img/clients.svg";
import archive from "resources/img/archive.svg";
import basket from "resources/img/basket.svg";

import styles from "./index.module.scss";

interface IRightBar {
  isMenuOpen: boolean;
  modules: {
    members: boolean;
    archive: boolean;
    basket: boolean;
    clients: boolean;
    reports: boolean;
  };
}

const RightBar: React.FC<IRightBar> = ({ isMenuOpen, modules }: IRightBar) => {
  return (
    <div className={styles.grid + " " + (isMenuOpen ? styles.grid_active : "")}>
      <div className={styles.topWrap}>
        <NavLink
          to="/boards"
          className={styles.item}
          activeClassName={styles.item_active}
        >
          <SVG src={boards} className={styles.icon} />
          <span>Доски</span>
        </NavLink>
        {modules.members && (
          <NavLink
            to="/members"
            className={styles.item}
            activeClassName={styles.item_active}
          >
            <SVG src={members} className={styles.icon} />
            <span>Сотрудники</span>
          </NavLink>
        )}
        {modules.clients && (
          <NavLink
            to="/clients"
            className={styles.item}
            activeClassName={styles.item_active}
          >
            <SVG src={clients} className={styles.icon} />
            <span>Клиенты</span>
          </NavLink>
        )}
      </div>
      <div className={styles.bottomWrap}>
        {modules.archive && (
          <NavLink
            to="/archive"
            className={styles.item}
            activeClassName={styles.item_active}
          >
            <SVG src={archive} className={styles.icon} />
            <span>Архив</span>
          </NavLink>
        )}
        {modules.basket && (
          <NavLink
            to="/basket"
            className={styles.item}
            activeClassName={styles.item_active}
          >
            <SVG src={basket} className={styles.icon} />
            <span>Корзина</span>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default RightBar;

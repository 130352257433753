import React from "react";
import SVG from "react-inlinesvg";
import { useDrop } from "react-dnd";

import CardPlaceholder from "../CardPlaceholder";
import Card from "../Card";

import styles from "./index.module.scss";

import startImg from "resources/img/stepStart.svg";
import finishImg from "resources/img/stepFinish.svg";

interface IColumnItem {
  className: any;
  column: any;
  setCardIdAction: Function;
  onCardDrop: Function;
}

const Column: React.FC<IColumnItem> = ({
  className,
  column,
  setCardIdAction,
  onCardDrop,
}: IColumnItem) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "card",
    drop: () => ({ column: column.id, position: column.cards.length }),
    canDrop: canCardMove,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  //Проверяем, являетя ли текущая колонка доступной для перехода перетягиваемой карточки
  function canCardMove(item) {
    return (
      item.card.column == column.id ||
      item.transitions.indexOf(column.id) !== -1
    );
  }
  return (
    <div className={className + (canDrop ? " " + styles.column_canDrop : "")}>
      <div className={styles.step} key={column.id}>
        {/* //Мишень для перетягивания карточки на колонку */}
        <div ref={drop} className={styles.step__dropTarget} />

        <div className={styles.head}>
          <div className={styles.counter}>{column.cards.length}</div>
          <div className={styles.name}>{column.title}</div>
          <div className={styles.icon}>
            <SVG
              className={
                styles.img +
                " " +
                (column.type === "first" ? styles.img_active : "")
              }
              src={startImg}
            />
            <SVG
              className={
                styles.img +
                " " +
                (column.type === "last" ? styles.img_active : "")
              }
              src={finishImg}
            />
          </div>
        </div>
        <div className={styles.wrap}>
          {column.cards.map((card) => (
            <Card
              onCardDrop={onCardDrop}
              setCardIdAction={setCardIdAction}
              card={card}
              column={column}
              key={card.id}
            />
          ))}
        </div>
        {canDrop && isOver && (
          <div className={styles.cardWrapper}>
            <CardPlaceholder />
          </div>
        )}
      </div>
    </div>
  );
};

export default Column;

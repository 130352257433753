import React from 'react';

import st from './index.module.scss';

interface IInput {
    width: string,
    value: any,
    disabled: boolean,
    onChange: any,
    height?: string,
    bcg?: string
}

const Input: React.FC<IInput> = ({width, value, disabled, onChange, height, bcg}: IInput) => {
    return (
        <input 
            className={st.wrap + " " + (disabled ? st.disabled : '')}
            style={{width: width+"px", height: height+"px", backgroundColor: '#'+bcg}}
            value={value}
            disabled={disabled}
            onChange={onChange}
        />
    )
}

export  default Input;